import React from "react"
import Svg, {Path} from "react-native-svg"

type Props = {
  active?: boolean
  size?: number
}

export function SettingBottom(props: Props) {
  const {active, size} = props
  return (
    <Svg width={size} height={size} viewBox="0 0 22 21" fill="none">
      <Path
        fill={active ? "#5653EC" : "none"}
        d="M1 11.3189V9.5589C1 8.5189 1.85 7.6589 2.9 7.6589C4.71 7.6589 5.45 6.3789 4.54 4.8089C4.02 3.9089 4.33 2.7389 5.24 2.2189L6.97 1.2289C7.76 0.758901 8.78 1.0389 9.25 1.8289L9.36 2.0189C10.26 3.5889 11.74 3.5889 12.65 2.0189L12.76 1.8289C13.23 1.0389 14.25 0.758901 15.04 1.2289L16.77 2.2189C17.68 2.7389 17.99 3.9089 17.47 4.8089C16.56 6.3789 17.3 7.6589 19.11 7.6589C20.15 7.6589 21.01 8.5089 21.01 9.5589V11.3189C21.01 12.3589 20.16 13.2189 19.11 13.2189C17.3 13.2189 16.56 14.4989 17.47 16.0689C17.99 16.9789 17.68 18.1389 16.77 18.6589L15.04 19.6489C14.25 20.1189 13.23 19.8389 12.76 19.0489L12.65 18.8589C11.75 17.2889 10.27 17.2889 9.36 18.8589L9.25 19.0489C8.78 19.8389 7.76 20.1189 6.97 19.6489L5.24 18.6589C4.33 18.1389 4.02 16.9689 4.54 16.0689C5.45 14.4989 4.71 13.2189 2.9 13.2189C1.85 13.2189 1 12.3589 1 11.3189Z"
        stroke={active ? "#FFF" : "#808185"}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <Path
        fill={active ? "#fff" : "none"}
        d="M11 13.4395C12.6569 13.4395 14 12.0963 14 10.4395C14 8.7826 12.6569 7.43945 11 7.43945C9.34315 7.43945 8 8.7826 8 10.4395C8 12.0963 9.34315 13.4395 11 13.4395Z"
        stroke={active ? "#FFF" : "#808185"}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Svg>
  )
}
