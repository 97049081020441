import React from "react"
import Svg, {Circle, Path} from "react-native-svg"

type Props = {
  active?: boolean
  size?: number
}

export function StoreBottom(props: Props) {
  const {active, size} = props
  return (
    <Svg width={size} height={size} viewBox="0 0 20 22" fill="none">
      <Path
        d="M5.5 6.67001V5.70001C5.5 3.45001 7.31 1.24001 9.56 1.03001C12.24 0.770009 14.5 2.88001 14.5 5.51001V6.89001"
        stroke={active ? "#5653EC" : "#808185"}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <Path
        fill={active ? "#5653EC" : "none"}
        d="M7.00007 21H13.0001C17.0201 21 17.7401 19.39 17.9501 17.43L18.7001 11.43C18.9701 8.99 18.2701 7 14.0001 7H6.00007C1.73007 7 1.03007 8.99 1.30007 11.43L2.05007 17.43C2.26007 19.39 2.98007 21 7.00007 21Z"
        stroke={active ? "#5653EC" : "#808185"}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <Circle cx="13.25" cy="11" r="1" fill={active ? "#FFF" : "#808185"} />
      <Circle cx="6.25" cy="11" r="1" fill={active ? "#FFF" : "#808185"} />
    </Svg>
  )
}
