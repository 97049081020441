const ru = {
  common: {
    ok: "OK!",
    cancel: "Cancel",
    back: "Back",
    logOut: "Выход",
    continue: "Продолжить",
    error: "Ошибка",
    confirm: "Подтверждение",
  },
  modalContinue: {
    continue: "Продолжить",
    again: "Начать заново",
    title: "Хотите продолжить?",
    body: "Вы недавно играли в эту игру\nи не завершили",
  },
  mainModal: {
    demoModal: {
      titleOne: "Купите колоду, чтобы получить доступ к",
      titleTwo: "вопросам, которые вы сможете использовать в любое время",
      oneBtn: "Купить за 99 ₽",
      twoBtn: "Демо-версия",
    },
    blockCardModal: {
      title: "Спасибо за интерес к игре!",
      body: "Этот набор пока недоступен.\nПодпишитесь на уведомления, чтобы первыми получить доступ к игре.",
      oneBtn: "Подписаться",
      twoBtn: "Отмена",
    },
    pushModal: {
      title: "Разрешить уведомления?",
      body: "Чтобы быть в курсе последних обновлений и не упустить важное",
      oneBtn: "Разрешить",
      twoBtn: "Позже",
    },
  },
  bottomTab: {
    games: "Игры",
    store: "Магазин",
    setting: "Настройки",
  },
  policyScreen: {
    policy: "О приложении",
    agreement: "Пользовательское соглашение",
    politics: "Политика конфиденциальности",
    position: "Положение по обработке персональных данных",
  },
  companyScreen: {
    about: "О нас",
    textOne:
      "Мы занимаемся изучением истории семьи, составлением семейного древа, предоставляем возможность проведения самостоятельного генеалогического исследования по архивам с целью реконструкции истории семьи или судьбы конкретного человека.",
    textTwo:
      "Сервис разработан командой экспертов Международного Генеалогического Центра, каждый из которых имеет опыт более 20 лет в генеалогии, семейной истории и архивном деле.",
  },
  languageScreen: {
    language: "Язык",
    ru: "Русский (RU)",
    en: "Английский (EN)",
    es: "Испанский (ES)",
  },
  swipeModal: {
    howToPlay: "Как играть",
    body: "«Между нами» — это проводник в душевный, глубокий и живой разговор с теми, с кем хочется стать ближе.",
    secondBody: "Правила простые, а победители — все, кто участвует с открытым сердцем.",
    text: "Кого вы хотите узнать ближе? Может быть подругу, маму, вашего ребёнка или компанию друзей.;Организуйте удобное всем время и место для разговора, можно даже онлайн;Задавайте друг другу вопросы с карточек и отмечайте сердечком любимые;Если вопрос сложный в моменте, можно его пропустить или вернуться позже;Отвечайте так, как чувствуете. Правильных и неправильных ответов нет;Слушайте внимательно, это поможет вашему собеседнику открыться;Вы можете закончить игру, когда захочется, и продолжить в любой момент",
  },
  welcomeScreen: {
    oneScreen: {
      title: "Перед вами\nигра-беседа",
      text: "от создателей сервиса по изучению истории семьи Famiry",
    },
    twoScreen: {
      title: "Для тех, кто хочет быть ближе",
      text: "Иногда всего один вопрос может открыть новую главу отношений",
    },
    threeScreen: {
      title: "Разные темы\nпод настроение",
      text: "В игре не может быть правильных или неправильных ответов",
    },
  },
  errorScreen: {
    title: "",
    friendlySubtitle: "",
    reset: "",
  },
  gameScreen: {
    from: " из ",
    backQuestion: "Вернуть вопрос",
    finalCardBody: "Сделайете селфи все вместе и пририсуйте каждому что-нибудь на фото",
    buyFor: "Купить за",
    endGame: "Завершить игру",
    toAccess: "Чтобы получить доступ к",
    buyFullGame: "вопросам купите полную-версию",
  },
  settingScreen: {
    setting: "Настройки",
    language: {
      title: "Язык",
      body: "Русский сейчас включен",
    },
    shop: {
      title: "Мои покупки",
      body: "Вы пока ничего не купили",
    },
    message: {
      title: "Обратная связь",
      body: "Напишите нам",
    },
    info: {
      title: "О нас",
      body: "Подробнее о проекте Famiry",
    },
    telegram: {
      title: "Связаться с нами",
      body: "Напишите нам в Telegram",
    },
    phone: "О приложении",
  },
  storeScreen: {
    store: "Магазин",
    void: "Скоро открытие",
    text: "Здесь вы сможете купить оффлайн версии игр, открытки, футболки и многое другое",
  },
  emptyStateComponent: {
    generic: {
      heading: "",
      content: "",
      button: "",
    },
  },
  loginScreen: {
    codeAgain: "Выслать код заново",
    logInUsing: "Войдите с помощью",
    codeError: "Неправильный код",
    policyText: "Продолжая, Вы соглашаетесь с нашим",
    policy: "Положением по обработке персональных данных",
    agreement: "Пользовательским соглашением",
    and: "и",
    firstText: "Пожалуйста, укажите свой электронный адрес, чтобы начать игру",
    smsCodeSending: "Код отправлен на email",
    send: "Используем ваш email, чтобы вы могли сохранить свой прогресс в игре ",
    sendCode: "Получить код",
    signIn: "Вход",
    enterDetails: "Введите логин и пароль Famiry Card",
    emailFieldLabel: "Email",
    passwordFieldLabel: "Пароль",
    emailFieldPlaceholder: "Введите email",
    passwordFieldPlaceholder: "Введите пароль",
    tapToSignIn: "Войти",
    hint: "",
  },
  buttons: {
    continue: "Продолжить",
  },
}

export default ru
export type Translations = typeof ru
