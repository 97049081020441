/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { CardCollectionTagModel, CardCollectionTagModelType } from "./CardCollectionTagModel"
import { CardCollectionTagModelSelector } from "./CardCollectionTagModel.base"
import { CardModel, CardModelType } from "./CardModel"
import { CardModelSelector } from "./CardModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  tag: CardCollectionTagModelType;
}

/**
 * CardCollectionBase
 * auto generated base class for the model CardCollectionModel.
 */
export const CardCollectionModelBase = withTypedRefs<Refs>()(ModelBase
  .named('CardCollection')
  .props({
    __typename: types.optional(types.literal("CardCollection"), "CardCollection"),
    id: types.identifier,
    createdAt: types.union(types.undefined, types.frozen()),
    updatedAt: types.union(types.undefined, types.frozen()),
    index: types.union(types.undefined, types.number),
    status: types.union(types.undefined, types.number),
    title: types.union(types.undefined, types.string),
    description: types.union(types.undefined, types.string),
    howToPlay: types.union(types.undefined, types.string),
    passageTime: types.union(types.undefined, types.number),
    freeToUse: types.union(types.undefined, types.boolean),
    backgroundColor: types.union(types.undefined, types.string),
    img: types.union(types.undefined, types.null, types.string),
    cards: types.union(types.undefined, types.array(types.late((): any => CardModel))),
    cardsCount: types.union(types.undefined, types.number),
    tag: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => CardCollectionTagModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class CardCollectionModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  get index() { return this.__attr(`index`) }
  get status() { return this.__attr(`status`) }
  get title() { return this.__attr(`title`) }
  get description() { return this.__attr(`description`) }
  get howToPlay() { return this.__attr(`howToPlay`) }
  get passageTime() { return this.__attr(`passageTime`) }
  get freeToUse() { return this.__attr(`freeToUse`) }
  get backgroundColor() { return this.__attr(`backgroundColor`) }
  get img() { return this.__attr(`img`) }
  get cardsCount() { return this.__attr(`cardsCount`) }
  cards(builder: string | CardModelSelector | ((selector: CardModelSelector) => CardModelSelector) | undefined) { return this.__child(`cards`, CardModelSelector, builder) }
  tag(builder: string | CardCollectionTagModelSelector | ((selector: CardCollectionTagModelSelector) => CardCollectionTagModelSelector) | undefined) { return this.__child(`tag`, CardCollectionTagModelSelector, builder) }
}
export function selectFromCardCollection() {
  return new CardCollectionModelSelector()
}

export const cardCollectionModelPrimitives = selectFromCardCollection().createdAt.updatedAt.index.status.title.description.howToPlay.passageTime.freeToUse.backgroundColor.img.cardsCount
