import React, {Dispatch, FC, useEffect, useRef, useState} from "react"
import {
  Alert,
  Animated,
  Dimensions,
  LayoutAnimation,
  Pressable,
  TextStyle,
  View,
  ViewStyle,
} from "react-native"
import {CardItem, TData} from "./CardItem"
import {CardModelType} from "../../models/generated"
import {observer} from "mobx-react-lite"
import {Text} from "../Text"
import ShareIcon from "../../../assets/icons/cards/share.svg"
import QuestionIcon from "../../../assets/icons/cards/qustionCards.svg"
import LikeIcon from "../../../assets/icons/cards/likeCard.svg"
import LikeIconOn from "../../../assets/icons/cards/likeCardOn.svg"
import {captureRef} from "react-native-view-shot"
import * as Sharing from "expo-sharing"
import {colorsFamiry} from "../../theme"
import {translate} from "../../i18n"
import {useStores} from "../../models"
import {normalize} from "../../utils/textHelp"
import {CardItemEmpty} from "./CardItemEmpty"
import {IS_WEB, MAX_WIDTH} from "../../utils/const"

const {width} = Dimensions.get("window")

const cardsInDeck = 3

export type SwipeCardProps = {
  setCount: Dispatch<number>
  count: number
  cards: CardModelType[]
  selectedCard: string | null
  endPress: () => void
  questionPress: () => void
  backgroundColor: string
  cardCollectionId?: string
  demo?: boolean
}

export const MAX_DEMO_CARDS = 16

export const SwipeCard: FC<SwipeCardProps> = observer(function SwipeCard({
                                                                           setCount,
                                                                           count,
                                                                           cards,
                                                                           selectedCard,
                                                                           endPress,
                                                                           questionPress,
                                                                           backgroundColor,
                                                                           cardCollectionId,
                                                                           demo,
                                                                         }) {
  const maxCardCount = cards.length
  const opacity = new Animated.Value(0)
  const [data, setData] = useState<TData[]>([])
  const containerRef = useRef<View>(null)
  const [isAddDemoCard, setIsAddDemoCard] = useState(false)
  const {mutateToggleCardLike, setLike} = useStores()
  
  const onLike = async () => {
    let count
    if (!data[0].card.isLikedByUser) {
      count = data[0].card.likesCount + 1
    } else {
      count = data[0].card.likesCount - 1
    }
    let newData = [...data]
    newData.splice(0, 1)
    newData = [
      {
        ...data[0],
        card: {
          ...data[0].card,
          isLikedByUser: !data[0].card.isLikedByUser,
          likesCount: count,
        },
      },
      ...newData,
    ]
    setData(newData)
    try {
      await mutateToggleCardLike({input: {card: data[0].card.id}}).then((r) => {
        console.log(r.toggleCardLike)
        
        if (cardCollectionId)
          setLike(cardCollectionId, data[0].card.id, !data[0].card.isLikedByUser, count)
      })
    } catch (error) {
      let newData = [...data]
      newData.splice(0, 1)
      newData = [
        {
          ...data[0],
          card: {
            ...data[0].card,
            isLikedByUser: data[0].card.isLikedByUser,
            likesCount: data[0].card.likesCount,
          },
        },
        ...newData,
      ]
      setData(newData)
    }
  }
  
  const finalCard = (id: number) => {
    return {
      id,
      text: translate("gameScreen.finalCardBody"),
      title: "финал",
      cardId: 0,
      isEnd: true,
    }
  }
  
  const demoPress = (fn: () => void) => {
    Alert.alert("оплата", "тут будет окно оплаты", [
      {
        text: "успешно",
        onPress: () => {
          fn()
        },
      },
      {
        text: "отмена",
      },
    ])
    console.log("demo")
  }
  
  const newCardArray = () => {
    const dataArray: TData[] = []
    let count = 0
    if (cards.length >= 3) {
      count = 3
    } else {
      count = cards.length
    }
    for (let i = 0; i < count; i += 1) {
      dataArray.push({
        id: i,
        text: cards[i].question,
        title: cards[i].title,
        cardId: i + 1,
        card: cards[i],
      })
    }
    setData(dataArray)
  }
  const viewRef = useRef<View>()
  const shareDummyImage = async () => {
    try {
      const uri = await captureRef(viewRef, {
        format: "png",
        quality: 0.7,
      })
      await Sharing.shareAsync(uri)
      // await Share.open({url: uri})
    } catch (err) {
      console.error(err)
    }
  }
  
  const continueCardArray = (selectCard: number) => {
    const dataArray: TData[] = []
    let key = 0
    if (selectCard + 2 > maxCardCount) {
      if (selectCard + 1 > maxCardCount) {
        dataArray.push({
          id: 0,
          text: cards[selectCard - 1].question,
          title: cards[selectCard - 1].title,
          cardId: selectCard,
          isEnd: false,
          card: cards[selectCard - 1],
        })
        // dataArray.push(finalCard(1))
      } else {
        for (let i = selectCard - 1; i < selectCard + 1; i += 1) {
          dataArray.push({
            id: key,
            text: cards[i].question,
            title: cards[i].title,
            cardId: i + 1,
            card: cards[i],
            isEnd: false,
          })
          key += 1
        }
        // dataArray.push(finalCard(2))
      }
    } else {
      for (let i = selectCard - 1; i < selectCard + 2; i += 1) {
        dataArray.push({
          id: key,
          text: cards[i].question,
          title: cards[i].title,
          card: cards[i],
          cardId: i + 1,
          isEnd: false,
        })
        key += 1
      }
    }
    
    setData(dataArray)
  }
  
  useEffect(() => {
    if (selectedCard) {
      continueCardArray(Number(selectedCard))
    } else {
      newCardArray()
    }
  }, [cards])
  
  const nextItem = () => {
    if (demo && count + 3 === MAX_DEMO_CARDS && !isAddDemoCard) {
      let newData = [...data]
      newData.splice(0, 1)
      newData = [
        ...newData,
        {
          id: data[0].id,
          text: `${translate("gameScreen.toAccess")} ${String(maxCardCount)} ${translate(
            "gameScreen.buyFullGame",
          )}`,
          title: "ДЕМО-ВЕРСИЯ ЗАВЕРШЕНА",
          card: cards[count + 2],
          cardId: 999,
          isEnd: false,
          demoCard: true,
        },
      ]
      
      LayoutAnimation.easeInEaseOut()
      setIsAddDemoCard(true)
      setData(newData)
    } else if (count < maxCardCount + 1) {
      if (count < maxCardCount + 1 - cardsInDeck) {
        let newData = [...data]
        newData.splice(0, 1)
        newData = [
          ...newData,
          {
            id: data[0].id,
            text: cards[count + 2].question,
            title: cards[count + 2].title,
            card: cards[count + 2],
            cardId: count + 3,
            isEnd: false,
            demoCard: false,
          },
        ]
        LayoutAnimation.easeInEaseOut()
        setData(newData)
      } else {
        let newData = [...data]
        newData.splice(0, 1)
        newData = [...newData]
        LayoutAnimation.easeInEaseOut()
        setData(newData)
      }
      if (!(count === maxCardCount)) {
        setCount(count + 1)
      }
    }
  }
  
  const prevItem = () => {
    if (count > 1) {
      if (count <= maxCardCount + 1 - cardsInDeck) {
        let newData = [...data]
        newData.splice(2, 1)
        newData = [
          {
            id: data[2].id,
            text: cards[count - 2].question,
            title: cards[count - 2].title,
            cardId: count - 1,
            card: cards[count - 2],
            isEnd: false,
          },
          ...newData,
        ]
        setData(newData)
      } else {
        let newData = [...data]
        let id
        if (newData[0].id - 1 === -1) {
          id = 2
        } else {
          id = newData[0].id - 1
        }
        newData = [
          {
            id,
            text: cards[count - 2].question,
            title: cards[count - 2].title,
            cardId: count - 1,
            card: cards[count - 2],
            isEnd: false,
          },
          ...newData,
        ]
        setData(newData)
      }
      setCount(count - 1)
    }
  }
  
  const setAction = () => {
    console.log(normalize(16))
  }
  return (
    <View style={$allContainer}>
      <View>
        <View ref={viewRef} style={$screenshotContainer}>
          <Text size={"H2regular"} style={$screenshotText} text={cards[count - 1].question} />
          <Text style={$hashtag} text={"#междунами"} />
        </View>
      </View>
      
      <View style={[$mainContainer, {backgroundColor}]}>
        <Animated.View style={[$absoluteFill, {opacity}]} ref={containerRef} />
        <View style={$container}>
          <CardItemEmpty id={1} />
          <CardItemEmpty id={2} />
          {data.map((item, index) => (
            <CardItem
              cardCount={maxCardCount}
              key={item.cardId}
              item={item}
              i={index}
              id={item.id}
              nextItem={nextItem}
              data={data}
              setAction={setAction}
              endPress={endPress}
              demoPress={demoPress}
            />
          ))}
        </View>
      </View>
      <View style={$bottomContainer}>
        <Pressable style={$btnContainer} onPress={prevItem}>
          <Text text={translate("gameScreen.backQuestion")} />
        </Pressable>
        <View style={$iconsContainer}>
          <View style={$likeContainer}>
            {data[0]?.card.isLikedByUser ? (
              <Pressable onPress={onLike}>
                <LikeIconOn />
              </Pressable>
            ) : (
              <Pressable onPress={onLike}>
                <LikeIcon />
              </Pressable>
            )}
            <Text style={$likeText} text={String(data[0]?.card.likesCount)} />
          </View>
          <View style={$rightIcons}>
            {!IS_WEB && <Pressable style={$share} onPress={shareDummyImage}>
              <ShareIcon />
            </Pressable>}
            <Pressable onPress={questionPress}>
              <QuestionIcon />
            </Pressable>
          </View>
        </View>
      </View>
    </View>
  )
})

const $absoluteFill: ViewStyle = {
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
}

const $container: TextStyle = {
  padding: 10,
  color: "#fff",
  flex: 1,
}

const $allContainer: TextStyle = {
  flex: 1,
  width: IS_WEB ? "100%" : undefined,
  maxWidth: MAX_WIDTH,
}
const $bottomContainer: TextStyle = {
  justifyContent: "flex-end",
  width: IS_WEB ? "100%" : undefined,
  
}
const $screenshotText: TextStyle = {
  textAlign: "center",
  fontSize: 22.5,
  lineHeight: 28,
  color: colorsFamiry.black,
}
const $screenshotContainer: ViewStyle = {
  position: "absolute",
  width: 311,
  minHeight: 415,
  borderRadius: 24,
  backgroundColor: colorsFamiry.white,
  padding: 20,
  top: -5000,
  alignItems: "center",
  justifyContent: "center",
}
const $hashtag: TextStyle = {
  position: "absolute",
  bottom: 20,
}
const $mainContainer: ViewStyle = {
  flex: 1,
  width: IS_WEB ? "100%" : undefined,
  maxWidth: MAX_WIDTH,
}
const $share: ViewStyle = {
  marginRight: 10,
}
const $rightIcons: ViewStyle = {
  flexDirection: "row",
}
const $btnContainer: ViewStyle = {
  width: IS_WEB ? undefined : width,
  alignItems: "center",
  marginBottom: 52,
  marginTop: IS_WEB ? 0 : 50,
  zIndex: -1,
}

const $iconsContainer: ViewStyle = {
  width: IS_WEB ? "100%" : width,
  flex: IS_WEB ? 1 : undefined,
  flexDirection: "row",
  justifyContent: "space-between",
  paddingHorizontal: 16,
  marginBottom: 15,
  zIndex: -1,
}
const $likeContainer: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
}
const $likeText: TextStyle = {
  marginLeft: 8,
}
