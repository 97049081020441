import {Animated, TextStyle, View, ViewStyle} from "react-native"
import React, {FC} from "react"
import {colorsFamiry} from "../../theme"
import {observer} from "mobx-react-lite"
import {IS_WEB} from "../../utils/const"

export type CardItemEmptyProps = {id: number}

export const CardItemEmpty: FC<CardItemEmptyProps> = observer(function CardItemEmpty({id}) {
  const rotateReg = (i: number) => {
    switch (i) {
      case 0:
        return "0deg"
      case 1:
        return "-2.5deg"
      case 2:
        return "2.5deg"
      default:
        return "0deg"
    }
  }
  
  return (
    <Animated.View style={[$absoluteFill, $center]}>
      <Animated.View style={$item}>
        <View
          style={[
            $card,
            {
              transform: [{rotate: rotateReg(id)}],
            },
          ]}
        ></View>
      </Animated.View>
    </Animated.View>
  )
})

const $center: ViewStyle = {
  alignItems: "center",
  justifyContent: "center",
}

const $item: ViewStyle = {
  alignItems: "center",
  justifyContent: "center",
  width: 311,
  minHeight: IS_WEB ? 400 : 300,
  marginTop: 40,
  maxHeight: 470,
}
const $backIcon: ViewStyle = {
  position: "absolute",
  bottom: 40,
  left: 0,
  right: 0,
  alignItems: "center",
}
const $title: ViewStyle = {
  position: "absolute",
  top: 40,
  left: 0,
  right: 0,
  alignItems: "center",
}
const $btn: ViewStyle = {
  width: "85%",
  borderRadius: 18,
}
const $card: ViewStyle = {
  height: "100%",
  width: "100%",
  backgroundColor: colorsFamiry.white,
  borderColor: colorsFamiry.grey10,
  borderWidth: 1,
  paddingHorizontal: 20,
  borderRadius: 24,
  alignItems: "center",
  justifyContent: "center",
}
const $absoluteFill: ViewStyle = {
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
}

const $cardText: TextStyle = {
  fontSize: 22.5,
  lineHeight: 28,
  color: colorsFamiry.black,
  textAlign: "center",
}
const $countText: TextStyle = {
  color: colorsFamiry.grey100,
}
