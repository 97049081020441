import Svg, {Path} from "react-native-svg"
import React from "react"

type Props = {
  active?: boolean
  size?: number
}

export function GameBottom(props: Props) {
  const {active, size} = props
  return (
    <Svg width={size} height={size} viewBox="0 0 22 21" fill="none">
      <Path
        fill={active ? "#5653EC" : "none"}
        d="M20.6743 8.93854L19.6943 13.1185C18.8543 16.7285 17.1943 18.1885 14.0743 17.8885C13.5743 17.8485 13.0343 17.7585 12.4543 17.6185L10.7743 17.2185C6.60434 16.2285 5.31434 14.1685 6.29434 9.98854L7.27434 5.79854C7.47434 4.94854 7.71434 4.20854 8.01434 3.59854C9.18434 1.17854 11.1743 0.528543 14.5143 1.31854L16.1843 1.70854C20.3743 2.68854 21.6543 4.75854 20.6743 8.93854Z"
        stroke={active ? "#FFF" : "#808185"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <Path
        fill={active ? "#5653EC" : "none"}
        d="M14.074 17.8886C13.454 18.3086 12.674 18.6586 11.724 18.9686L10.144 19.4886C6.17401 20.7686 4.08401 19.6986 2.79401 15.7286L1.51401 11.7786C0.234013 7.80863 1.29401 5.70863 5.26401 4.42863L6.84401 3.90863C7.25401 3.77863 7.64401 3.66863 8.01401 3.59863C7.71401 4.20863 7.47401 4.94863 7.27401 5.79863L6.29401 9.98863C5.31401 14.1686 6.60401 16.2286 10.774 17.2186L12.454 17.6186C13.034 17.7586 13.574 17.8486 14.074 17.8886Z"
        stroke={active ? "#FFF" : "#808185"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Svg>
  )
}
