import React, {Dispatch, FC} from "react"
import {Image, ImageStyle, Pressable, TextStyle, View, ViewStyle} from "react-native"
import {Text} from "../Text"
import Modal from "react-native-modal"
import {colorsFamiry} from "../../theme"
import {observer} from "mobx-react-lite"
import {Button} from "../Button"
import {BASE_URL, IS_WEB, MAX_WIDTH} from "../../utils/const"
import CloseIcon from "../../../assets/icons/closeIcon.svg"

export type FamiryModalProps = {
  isModal: boolean
  setIsModal: Dispatch<boolean>
  bodyText?: string
  titleText?: string
  button?: {
    oneBtnText: string
    oneBtnOnPress: () => void
    twoBtnText?: string
    twoBtnOnPress?: () => void
  }
  closeOnPress?: () => void
  imageUrl?: string
  color?: string
}

export const MainModal: FC<FamiryModalProps> = observer(function MainModal({
                                                                             isModal,
                                                                             setIsModal,
                                                                             bodyText,
                                                                             titleText,
                                                                             button,
                                                                             imageUrl,
                                                                             closeOnPress,
                                                                             color,
                                                                           }) {
  return (
    
    <Modal
      style={$modalContainer}
      testID={"modal"}
      onBackdropPress={() => {
        setIsModal(false)
      }}
      isVisible={isModal}
    >
      <View style={$Container}>
        {imageUrl && (
          <View style={{...$imageContainer, backgroundColor: color || "#FFF"}}>
            <Image style={$img} source={{uri: BASE_URL + imageUrl}} resizeMode={"stretch"} />
          </View>
        )}
        <View style={$contentContainer}>
          <View style={$textContainer}>
            <Text style={$text} size={"B1bold"} text={titleText} />
            <Text style={$text} size={"B3regular"} text={bodyText} />
          </View>
          {button && (
            <View style={$btnContainer}>
              <Button
                preset={"blue"}
                onPress={button.oneBtnOnPress}
                text={button.oneBtnText}
                style={$oneBtn}
              />
              {button.twoBtnText && (
                <Pressable onPress={button.twoBtnOnPress} style={$twoBtn}>
                  <Text style={$btnText} size={"B2bold"} text={button.twoBtnText} />
                </Pressable>
              )}
            </View>
          )}
        </View>
        {closeOnPress && <Pressable style={$icon} onPress={closeOnPress}>
          <CloseIcon />
        </Pressable>}
      </View>
    </Modal>
  )
})
const $textContainer: ViewStyle = {
  alignItems: "center",
  marginBottom: 10,
}
const $modalContainer: ViewStyle = {
  alignItems: "center",
}

const $btnContainer: ViewStyle = {
  alignItems: "center",
}
const $oneBtn: ViewStyle = {
  paddingVertical: 9,
  paddingHorizontal: 20,
  borderRadius: 12,
  width: "100%",
}

const $twoBtn: ViewStyle = {
  paddingVertical: 9,
  marginTop: 12,
}
const $icon: ViewStyle = {
  position: "absolute",
  top: 20,
  right: 20,
}
const $img: ImageStyle = {
  width: "80%",
  height: "100%",
}

const $Container: ViewStyle = {
  backgroundColor: colorsFamiry.white,
  borderTopLeftRadius: 21,
  borderTopRightRadius: 21,
  borderRadius: 20,
  width: IS_WEB ? "100%" : undefined,
  maxWidth: MAX_WIDTH,
}

const $contentContainer: ViewStyle = {
  padding: 20,
}
const $imageContainer: ViewStyle = {
  width: "100%",
  height: 200,
  paddingHorizontal: 20,
  paddingTop: 20,
  borderTopLeftRadius: 20,
  borderTopRightRadius: 20,
  alignItems: "center",
  justifyContent: "flex-end",
}

const $btnText: TextStyle = {
  color: colorsFamiry.black,
}

const $text: TextStyle = {
  textAlign: "center",
  marginBottom: 10,
}
