import {
  DarkTheme,
  DefaultTheme,
  NavigationContainer,
  NavigatorScreenParams,
} from "@react-navigation/native"
import {createNativeStackNavigator} from "@react-navigation/native-stack"
import {StackScreenProps} from "@react-navigation/stack"
import {observer} from "mobx-react-lite"
import React, {useEffect, useState} from "react"
import {Linking, useColorScheme} from "react-native"
import Config from "../config"
import {WelcomeScreen} from "../screens"
import {navigationRef, useBackButtonHandler} from "./navigationUtilities"
import {useStores} from "../models"
import {BottomNavigator, BottomTabParamList} from "./BottomNavigator"
import AsyncStorage from "@react-native-async-storage/async-storage"
import {SplashScreen} from "../screens/SplashScreen"
import {LoginScreen} from "../screens/Login/LoginScreen"
import {GamePreviewScreen} from "../screens/GamePreviewScreen"
import {CardCollectionModelType, CardModelType} from "../models/generated"
import {GameScreen} from "../screens/GameScreen"
import {colorsFamiry} from "../theme"
import {translate} from "../i18n"
import {MainModal} from "../components/newComponents/MainModal"
import {requestUserPermission} from "../utils/notifications"
import {IS_WEB} from "../utils/const"
import {EmptyScreen} from "../screens/EmptyScreen"
import axios from "axios"
import {useCookies} from "react-cookie"
import {getEnv} from "mobx-state-tree"
import {Theme} from "@react-navigation/native/src/types"

const WhiteTheme: Theme = {
  dark: false,
  colors: {
    primary: "rgb(0, 122, 255)",
    background: "rgb(255, 255, 255)",
    card: "rgb(255, 255, 255)",
    text: "rgb(28, 28, 30)",
    border: "rgb(255, 255, 255)",
    notification: "rgb(255, 59, 48)",
  },
}

export type AppStackParamList = {
  Welcome: undefined
  Login: undefined
  Empty: undefined
  SplashScreen: undefined
  GamePreviewScreen: {cardCollection: CardCollectionModelType; demo?: boolean}
  GameScreen: {
    cardCollection?: CardCollectionModelType
    demo?: boolean
    selectedCard?: string | null
    specDeck?: {
      type: "favorites" | "popular"
      cards: CardModelType[]
    }
  }
  BottomTab: NavigatorScreenParams<BottomTabParamList>
}

const exitRoutes = Config.exitRoutes

export type AppStackScreenProps<T extends keyof AppStackParamList> = StackScreenProps<AppStackParamList,
  T>

const Stack = createNativeStackNavigator<AppStackParamList>()

const AppStack = observer(function AppStack() {
  const {
    authenticationStore: {isAuthenticated},
  } = useStores()
  
  return (
    <Stack.Navigator
      screenOptions={{headerShown: false, contentStyle: {flex: 1, backgroundColor: colorsFamiry.white}}}
      initialRouteName={isAuthenticated ? "Empty" : (IS_WEB ? "Empty" : "Welcome")}
    
    >
      {isAuthenticated ? (
        <>
          <Stack.Screen name="BottomTab" component={BottomNavigator} />
          <Stack.Screen name="GamePreviewScreen" component={GamePreviewScreen} />
          <Stack.Screen name="GameScreen" component={GameScreen} />
          <Stack.Screen name="SplashScreen" component={SplashScreen} />
        </>
      ) : (
        <>
          <Stack.Screen name="Empty" component={EmptyScreen} />
          <Stack.Screen name="Welcome" component={WelcomeScreen} />
          <Stack.Screen name="Login" component={LoginScreen} />
          <Stack.Screen name="SplashScreen" component={SplashScreen} />
        </>
      )}
    </Stack.Navigator>
  )
})

interface NavigationProps extends Partial<React.ComponentProps<typeof NavigationContainer>> {
}

export const AppNavigator = observer(function AppNavigator(props: NavigationProps) {
  const colorScheme = useColorScheme()
  const {
    queryCurrent_user,
    authenticationStore: {isAuthenticated, setAuthToken},
  } = useStores()
  
  const store = useStores()
  const [isPushModal, setIsPushModal] = useState(false)
  const [redirect, setRedirect] = useState<"redirect" | "first" | "none">("first")
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"])
  const isPush = async () => {
    if (!IS_WEB) {
      AsyncStorage.getItem("isModalPush").then((r) => {
        if (!r) {
          setTimeout(() => {
            setIsPushModal(true)
            AsyncStorage.setItem("isModalPush", "true")
          }, 30000)
        }
      })
    }
  }
  useEffect(() => {
    if (isAuthenticated) {
      isPush()
    } else {
      if (cookies.access_token && cookies.access_token.access_token) {
        const cooke = cookies.access_token.access_token
        removeCookie("access_token")
        getEnv(store).gqlHttpClient.setHeaders({
          Authorization: "bearer " + cooke,
        })
        
        queryCurrent_user()
        AsyncStorage.setItem("token", cooke)
        setAuthToken(cooke)
        
      } else {
        window.open("https://cards.famiry.ru/auth/login", "_self")
      }
      
      
      // Linking.getInitialURL().then((url) => {
      //   console.log(url.indexOf("code="))
      //   console.log(url.split("code=")[1])
      //   if (url.split("code=")[1]) {
      //
      //   } else {
      //     // window.open("https://cards.famiry.ru/auth/login", "_self")
      //     //window.open("https://famiry.ru/id/oauth/authorize?client_id=980c66db-810b-4d15-8b28-ea01392b4ec4&response_type=code&redirect_uri=http://localhost:19006", "_self")
      //   }
      // })
      // Linking.openURL("https://famiry.ru/id/oa
      // uth/authorize?client_id=980c66db-810b-4d15-8b28-ea01392b4ec4&response_type=code&redirect_uri=http://localhost:19006")
      
      
    }
  }, [isAuthenticated])
  //
  
  const onPushPressSubscribe = async () => {
    await requestUserPermission()
    setIsPushModal(false)
  }
  
  const onPushPressCancel = () => {
    setIsPushModal(false)
  }
  useBackButtonHandler((routeName) => exitRoutes.includes(routeName))
  
  return (
    <NavigationContainer
      ref={navigationRef}
      theme={WhiteTheme}
      
      {...props}
    >
      <AppStack />
      <MainModal
        isModal={isPushModal}
        setIsModal={setIsPushModal}
        bodyText={translate("mainModal.pushModal.body")}
        titleText={translate("mainModal.pushModal.title")}
        button={{
          oneBtnText: translate("mainModal.pushModal.oneBtn"),
          twoBtnText: translate("mainModal.pushModal.twoBtn"),
          oneBtnOnPress: onPushPressSubscribe,
          twoBtnOnPress: onPushPressCancel,
        }}
      />
    </NavigationContainer>
  )
})
