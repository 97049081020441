/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * CardLikeBase
 * auto generated base class for the model CardLikeModel.
 */
export const CardLikeModelBase = ModelBase
  .named('CardLike')
  .props({
    __typename: types.optional(types.literal("CardLike"), "CardLike"),
    id: types.identifier,
    createdAt: types.union(types.undefined, types.frozen()),
    updatedAt: types.union(types.undefined, types.frozen()),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class CardLikeModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
}
export function selectFromCardLike() {
  return new CardLikeModelSelector()
}

export const cardLikeModelPrimitives = selectFromCardLike().createdAt.updatedAt
