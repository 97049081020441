import {observer} from "mobx-react-lite"
import React, {FC, useEffect, useRef, useState} from "react"
import {Animated, Dimensions, ScrollView, View, ViewStyle} from "react-native"

import {AppStackScreenProps} from "../navigators"
import {useHeader} from "../utils/useHeader"
import {PreviewScreen} from "../components/PreviewScreen"
import AsyncStorage from "@react-native-async-storage/async-storage"
import {colorsFamiry} from "../theme"
import {translate} from "../i18n"
import {normalize, normalize2} from "../utils/textHelp"

const {width} = Dimensions.get("window")
const {height} = Dimensions.get("window")

function Indicator() {
  return <View style={$indicator}></View>
}

interface WelcomeScreenProps extends AppStackScreenProps<"Welcome"> {}

export const WelcomeScreen: FC<WelcomeScreenProps> = observer(function WelcomeScreen({navigation}) {
  const [screen, setScreen] = useState(1)
  const [indicatorPosition, setIndicatorPosition] = useState(null)
  const data = [
    {
      title: translate("welcomeScreen.oneScreen.title"),
      text: translate("welcomeScreen.oneScreen.text"),
      image: require("../../assets/images/welcome/welcomeOne.png"),
    },
    {
      title: translate("welcomeScreen.twoScreen.title"),
      text: translate("welcomeScreen.twoScreen.text"),
      image: require("../../assets/images/welcome/welcomeTwo.png"),
    },
    {
      title: translate("welcomeScreen.threeScreen.title"),
      text: translate("welcomeScreen.threeScreen.text"),
      image: require("../../assets/images/welcome/welcomeThree.png"),
    },
  ]
  function goNext() {
    navigation.navigate("Login")
    AsyncStorage.setItem("secondLogin", "true")
  }

  const scrollValue = useRef(new Animated.Value(0)).current

  const translateX = scrollValue.interpolate({
    inputRange: [0, width],
    outputRange: [0, 16],
  })
  const translateX2 = scrollValue.interpolate({
    inputRange: [width, width * 2],
    outputRange: [width, 0],
    extrapolateLeft: "identity",
  })

  useHeader(
    {
      rightIconSvg: screen === 4 ? undefined : "cross",
      rightIconSize: 15,
      onRightPress: goNext,
      title: screen === 4 ? translate("loginScreen.signIn") : "",
    },
    [screen]
  )
  useEffect(() => {
    console.log(indicatorPosition)
  }, [indicatorPosition])

  return (
    <View style={$container}>
      <ScrollView
        horizontal
        nestedScrollEnabled
        pagingEnabled
        scrollEventThrottle={16}
        scrollEnabled={screen !== 4}
        decelerationRate="fast"
        showsHorizontalScrollIndicator={false}
        onMomentumScrollEnd={() => {
          // @ts-ignore
          console.log(Math.round(scrollValue._value / width) + 1)
          // @ts-ignore
          setScreen(Math.round(scrollValue._value / width) + 1)
        }}
        onScroll={Animated.event([{nativeEvent: {contentOffset: {x: scrollValue}}}], {
          useNativeDriver: false,
        })}
      >
        <ScrollView horizontal disableScrollViewPanResponder disableIntervalMomentum>
          {data.map((item) => (
            <PreviewScreen
              position={indicatorPosition}
              setPosition={setIndicatorPosition}
              key={item.title}
              item={item}
            />
          ))}
          {/* <LoginScreen key={"login"} /> */}
        </ScrollView>
      </ScrollView>
      {screen !== 4 && (
        <Animated.View
          style={[$indicatorContainer, indicatorPosition && {top: indicatorPosition + 32}]}
          pointerEvents="none"
        >
          {data.map((item) => (
            <Indicator key={item.title} />
          ))}
          <View style={$indicator}></View>
          <Animated.View style={[$activeIndicator, {transform: [{translateX}]}]} />
        </Animated.View>
      )}
    </View>
  )
})

const $container: ViewStyle = {
  flex: 1,
}

const $indicatorContainer: ViewStyle = {
  alignSelf: "center",
  position: "absolute",
  flexDirection: "row",
}

const $indicator: ViewStyle = {
  height: 8,
  width: 8,
  borderRadius: 5,
  zIndex: 0,
  backgroundColor: colorsFamiry.grey10,
  marginHorizontal: 4,
}

const $activeIndicator: ViewStyle = {
  position: "absolute",
  marginLeft: 4,
  height: 8,
  width: 24,
  zIndex: 1,
  backgroundColor: colorsFamiry.accent,
  borderRadius: 10,
}
