import {Dimensions, PixelRatio, Platform} from "react-native"

export const {width: SCREEN_WIDTH, height: SCREEN_HEIGHT} = Dimensions.get("window")
export const scale = SCREEN_WIDTH / 350
export const scale2 = SCREEN_WIDTH / 360
export function normalize(size) {
  const newSize = size * scale
  if (Platform.OS === "ios") {
    return Math.round(PixelRatio.roundToNearestPixel(newSize))
  } else {
    return Math.round(PixelRatio.roundToNearestPixel(newSize)) - 2
  }
}

export function normalize2(size) {
  const newSize = size * scale2
  if (Platform.OS === "ios") {
    return Math.round(PixelRatio.roundToNearestPixel(newSize))
  } else {
    return Math.round(PixelRatio.roundToNearestPixel(newSize)) - 2
  }
}
export function declOfNum(n: number, textForms: string[]) {
  n = Math.abs(n) % 100
  const n1 = n % 10
  if (n > 10 && n < 20) {
    return textForms[2]
  }
  if (n1 > 1 && n1 < 5) {
    return textForms[1]
  }
  if (n1 === 1) {
    return textForms[0]
  }
  return textForms[2]
}
