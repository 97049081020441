/* eslint-disable  react-native/no-inline-styles */
import React, {FC} from "react"
import {Linking, View, ViewStyle} from "react-native"
import {useHeader} from "../../utils/useHeader"
import Language from "../../../assets/icons/setting/lang.svg"
import Info from "../../../assets/icons/setting/info.svg"
import Phone from "../../../assets/icons/setting/phone.svg"
import Telegram from "../../../assets/icons/setting/telegram.svg"

import {SettingItem} from "../../components/setting/SettingItem"
import {SettingStackScreenProps} from "../../navigators/SettingStack"
import {$headerTitleText} from "../../theme/style"
import {translate} from "../../i18n"
import {observer} from "mobx-react-lite"

interface SettingScreenProps extends SettingStackScreenProps<"SettingScreen"> {
}

export const SettingScreen: FC<SettingScreenProps> = observer(function SettingScreen({navigation}) {
  const data = [
    {
      title: translate("settingScreen.language.title"),
      body: translate("settingScreen.language.body"),
      icon: Language,
      onPress: () => {
        navigation.navigate("LanguageScreen")
      },
    },
    {
      title: translate("settingScreen.telegram.title"),
      body: translate("settingScreen.telegram.body"),
      icon: Telegram,
      onPress: () => {
        Linking.openURL("https://t.me/eto_mezhdu_nami_chat")
      },
    },
    {
      title: translate("settingScreen.info.title"),
      body: translate("settingScreen.info.body"),
      icon: Info,
      onPress: () => {
        navigation.navigate("CompanyScreen")
      },
    },
    {
      title: translate("settingScreen.phone"),
      icon: Phone,
      onPress: () => {
        navigation.navigate("PolicyScreen")
      },
    },
  ]
  
  useHeader({
    title: translate("settingScreen.setting"),
    titleMode: "center",
    titleStyle: $headerTitleText,
  })
  
  return (
    <View style={$container}>
      {data.map((item, index) => (
        <SettingItem last={index + 1 === data.length} key={index} item={item} />
      ))}
    </View>
  )
})
const $container: ViewStyle = {
  flex: 1,
}
