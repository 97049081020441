/**
 * Welcome to the main entry point of the app. In this file, we'll
 * be kicking off our app.
 *
 * Most of this file is boilerplate and you shouldn't need to modify
 * it very often. But take some time to look through and understand
 * what is going on here.
 *
 * The app navigation resides in ./app/navigators, so head over there
 * if you're interested in adding screens and navigators.
 */
import "./i18n"
import "./utils/ignoreWarnings"
import {useFonts} from "expo-font"
import React, {useEffect} from "react"
import {initialWindowMetrics, SafeAreaProvider} from "react-native-safe-area-context"
import * as Linking from "expo-linking"
import {useInitialRootStore, useStores} from "./models"
import {AppNavigator, useNavigationPersistence} from "./navigators"
import {ErrorBoundary} from "./screens/ErrorScreen/ErrorBoundary"
import {customFontsToLoad} from "./theme"
import * as storage from "./utils/storage"
import {setupReactotron} from "./services/reactotron"
import Config from "./config"
import AsyncStorage from "@react-native-async-storage/async-storage"
import {getEnv} from "mobx-state-tree"
import {initializeApp} from "firebase/app"
import * as Analytics from "expo-firebase-analytics"
import {View, ViewStyle} from "react-native"
import {MAX_WIDTH} from "./utils/const"
// Set up Reactotron, which is a free desktop app for inspecting and debugging
// React Native apps. Learn more here: https://github.com/infinitered/reactotron
setupReactotron({
  // clear the Reactotron window when the app loads/reloads
  clearOnLoad: true,
  // generally going to be localhost
  host: "localhost",
  // Reactotron can monitor AsyncStorage for you
  useAsyncStorage: true,
  // log the initial restored state from AsyncStorage
  logInitialState: true,
  // log out any snapshots as they happen (this is useful for debugging but slow)
  logSnapshots: false,
})

const firebaseConfig = {
  apiKey: "AIzaSyBtBjR--DmzPGVBLWWu6vBNWUTQ4eXZAJU",
  authDomain: "famirycards.firebaseapp.com",
  projectId: "famirycards",
  storageBucket: "famirycards.appspot.com",
  messagingSenderId: "76265054814",
  appId: "1:76265054814:web:1c403bc295fb6be7610acf",
  measurementId: "G-GKMDEMEJNV",
}

initializeApp(firebaseConfig)
Analytics.logEvent("init")
export const NAVIGATION_PERSISTENCE_KEY = "NAVIGATION_STATE"

// Web linking configuration
const prefix = Linking.createURL("/")
const config = {
  screens: {
    Login: "Login",
    GamePreviewScreen: "GamePreview",
    GameScreen: "Game",
    BottomTab: {
      screens: {
        GamesScreen: "Games",
        StoreScreen: "Store",
        SettingStack: "Setting",
      },
    },
  },
}

interface AppProps {
  hideSplashScreen: () => Promise<void>
}

function App(props: AppProps) {
  const {hideSplashScreen} = props
  const {
    initialNavigationState,
    onNavigationStateChange,
    isRestored: isNavigationStateRestored,
  } = useNavigationPersistence(storage, NAVIGATION_PERSISTENCE_KEY)
  
  const [areFontsLoaded] = useFonts(customFontsToLoad)
  
  const {rehydrated} = useInitialRootStore(() => {
    setTimeout(hideSplashScreen, 500)
  })
  const {
    authenticationStore: {setAuthToken},
  } = useStores()
  const store = useStores()
  const getToken = async () => {
    // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6bnVsbCwic3ViIjoiMjJmZGE4NGMtOTM1Ni00YTE4LTlkZjctNGMyM2VjOWJjZjUwIiwiaWF0IjoxNjg2NTAyNDc4LCJleHAiOjE5OTc1NDI0Nzh9.3DxyIas_BM3wAP4cBrgmZGtIcLWzL35Gapz3T3ZRuRQ"
    const token = await AsyncStorage.getItem("token")
    if (token) {
      getEnv(store).gqlHttpClient.setHeaders({Authorization: "bearer " + token})
      
      setAuthToken(token)
    }
  }
  
  useEffect(() => {
    // KeepAwake.activate()
    getToken()
  }, [])
  
  if (!rehydrated || !isNavigationStateRestored || !areFontsLoaded) return null
  
  const linking = {
    prefixes: [prefix],
    config,
  }
  
  // otherwise, we're ready to render the app
  return (
    <>
      <SafeAreaProvider initialMetrics={initialWindowMetrics}>
        <ErrorBoundary catchErrors={Config.catchErrors}>
          <View style={$container}>
            <View style={$containerContent}>
              <AppNavigator
                // linking={linking}
                initialState={initialNavigationState}
                onStateChange={onNavigationStateChange}
              />
            </View>
          </View>
        </ErrorBoundary>
      </SafeAreaProvider>
    </>
  )
}

export default App

const $container: ViewStyle = {
  flex: 1,
  alignItems: "center",
}
const $containerContent: ViewStyle = {
  flex: 1,
  width: "100%",
  maxWidth: MAX_WIDTH,
}
