import {Animated, Dimensions, PanResponder, Pressable, TextStyle, View, ViewStyle} from "react-native"
import React, {FC, useState} from "react"
import {Button, Text} from "../../components"
import {colorsFamiry} from "../../theme"
import {observer} from "mobx-react-lite"
import {CardModelType} from "../../models/generated"
import {translate} from "../../i18n"
import {IS_WEB} from "../../utils/const"
import {interpolate, useSharedValue} from "react-native-reanimated"

const {width, height} = Dimensions.get("window")
const offsetW = width / 5
const offsetH = height / 5
export type CardItemProps = {
  setAction: (color?: string) => void
  nextItem: () => void
  data: TData[]
  item: TData
  i: number
  id: number
  cardCount: number
  endPress: () => void
  demoPress: (fn: () => void) => void
}

export type TData = {
  id: number
  text: string
  cardId: number
  title: string
  card?: CardModelType
  isEnd?: boolean
  disable?: boolean
  demoCard?: boolean
}

export const CardItem: FC<CardItemProps> = observer(function CardItem({
                                                                        setAction,
                                                                        nextItem,
                                                                        data,
                                                                        i,
                                                                        item,
                                                                        id,
                                                                        cardCount,
                                                                        endPress,
                                                                        demoPress,
                                                                      }) {
  const pan = new Animated.ValueXY({x: 0, y: 0})
  const rotateReg = (i: number) => {
    switch (i) {
      case 0:
        return "0deg"
      case 1:
        return "0deg"
      case 2:
        return "0deg"
      default:
        return "0deg"
    }
  }
  const [x] = useState(new Animated.Value(0))
  const panResponder = PanResponder.create({
    onMoveShouldSetPanResponder: () => true,
    onPanResponderMove: Animated.event([null, {dx: pan.x, dy: pan.y}], {
      useNativeDriver: false,
    }),
    onPanResponderRelease: (e, g) => {
      const horizontal: boolean = Math.abs(g.vx) > 1 || Math.abs(g.dx) > offsetW
      const vertical: boolean = Math.abs(g.vy) > 1 || Math.abs(g.dy) > offsetH
      if ((horizontal || vertical) && !item.isEnd) {
        if (horizontal) {
          Animated.spring(pan, {
            toValue: {x: width * 2 * (g.dx < 0 ? -1 : 1), y: 0},
            useNativeDriver: true,
            bounciness: 0,
          }).start()
          setTimeout(() => {
            if (!(item.disable || item.demoCard || String(item.cardId) === String(cardCount)))
              if (!IS_WEB) nextItem()
          }, 100)
        } else if (vertical) {
          Animated.spring(pan, {
            toValue: {x: 0, y: height * 2 * (g.dy < 0 ? -1 : 1)},
            useNativeDriver: true,
            bounciness: 0,
          }).start()
          setTimeout(() => {
            if (!(item.disable || item.demoCard || String(item.cardId) === String(cardCount)))
              if (!IS_WEB) nextItem()
          }, 100)
        }
        setAction()
      } else {
        Animated.spring(pan, {
          toValue: {x: 0, y: 0},
          useNativeDriver: true,
        }).start()
      }
    },
    onPanResponderTerminate: () => {
      Animated.spring(pan, {
        toValue: {x: 0, y: 0},
        useNativeDriver: true,
      }).start()
    },
  })
  
  const onPressDemo = () => {
    demoPress(nextItem)
  }
  
  const renderBtn = () => {
    if (String(item.cardId) === String(cardCount)) {
      return (
        <Button
          onPress={endPress}
          style={$btn}
          preset={"blue"}
          text={translate("gameScreen.endGame")}
        />
      )
    } else if (item.demoCard) {
      return (
        <Button
          onPress={onPressDemo}
          style={$btn}
          preset={"blue"}
          text={`${translate("gameScreen.buyFor")} ${99} ₽`}
        />
      )
    } else {
      return (
        <Text
          size={"B3regular"}
          style={$countText}
          text={String(item.cardId) + " / " + String(cardCount)}
        />
      )
    }
  }
  
  return (
    <View style={[$absoluteFill, $center, {zIndex: data.length - i}]}>
      <Animated.View
        {...panResponder.panHandlers}
        style={[
          $item,
          !(String(item.cardId) === String(cardCount) || item.disable || item.demoCard) && {
            transform: [{translateX: IS_WEB ? x : pan.x}, {translateY: IS_WEB ? 0 : pan.y}],
          },
        ]}
      >
        <Pressable
          onPress={() => {
            if (IS_WEB && !(item.disable || item.demoCard || String(item.cardId) === String(cardCount))) {
              Animated.spring(x, {
                toValue: -500,
                useNativeDriver: true,
              }).start()
              setTimeout(() => {
                nextItem()
              }, 200)
            }
            
          }}
          style={[
            $card,
            {
              transform: [{rotate: rotateReg(id)}],
            },
          ]}
        >
          <View
            style={[
              $title,
              {
                transform: [{rotate: rotateReg(id)}],
              },
            ]}
          >
            <Text size={"B3regular"} style={$countText} text={item.title} />
          </View>
          <View style={$center}>
            <Text
              size={"H2regular"}
              style={[
                $cardText,
                {
                  transform: [{rotate: rotateReg(id)}],
                },
              ]}
              text={item.text}
            />
          </View>
          <View
            style={[
              $backIcon,
              {
                transform: [{rotate: rotateReg(id)}],
              },
            ]}
          >
            {renderBtn()}
          </View>
        </Pressable>
      </Animated.View>
    </View>
  )
})

const $center: ViewStyle = {
  alignItems: "center",
  justifyContent: "center",
}

const $item: ViewStyle = {
  alignItems: "center",
  justifyContent: "center",
  width: 311,
  marginTop: 40,
  minHeight: IS_WEB ? 400 : 300,
  maxHeight: 470,
}
const $backIcon: ViewStyle = {
  position: "absolute",
  bottom: 40,
  left: 0,
  right: 0,
  alignItems: "center",
}
const $title: ViewStyle = {
  position: "absolute",
  top: 40,
  left: 0,
  right: 0,
  alignItems: "center",
}
const $btn: ViewStyle = {
  width: "85%",
  borderRadius: 18,
}
const $card: ViewStyle = {
  height: "100%",
  width: "100%",
  backgroundColor: colorsFamiry.white,
  borderColor: colorsFamiry.grey10,
  borderWidth: 1,
  paddingHorizontal: 20,
  borderRadius: 24,
  alignItems: "center",
  justifyContent: "center",
}

const $absoluteFill: ViewStyle = {
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  // @ts-ignore
  "user-select": "none",
}

const $cardText: TextStyle = {
  fontSize: 22.5,
  lineHeight: 28,
  color: colorsFamiry.black,
  textAlign: "center",
}
const $countText: TextStyle = {
  color: colorsFamiry.grey100,
}
