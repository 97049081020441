import React, {FC} from "react"
import {ViewStyle} from "react-native"
import {Screen} from "../components"
import {spacing} from "../theme"
import Logo from "../../assets/images/splashLogo.svg"

export const SplashScreen: FC = () => {
  return (
    <Screen
      preset="auto"
      contentContainerStyle={$screenContentContainer}
      safeAreaEdges={["top", "bottom"]}
    >
      <Logo />
    </Screen>
  )
}

const $screenContentContainer: ViewStyle = {
  flex: 1,
  paddingVertical: spacing.huge,
  paddingHorizontal: spacing.large,
  justifyContent: "center",
  alignItems: "center",
}
