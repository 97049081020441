/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { QuizQuestionOptionModel, QuizQuestionOptionModelType } from "./QuizQuestionOptionModel"
import { QuizQuestionOptionModelSelector } from "./QuizQuestionOptionModel.base"
import { RootStoreType } from "./index"


/**
 * QuizQuestionBase
 * auto generated base class for the model QuizQuestionModel.
 */
export const QuizQuestionModelBase = ModelBase
  .named('QuizQuestion')
  .props({
    __typename: types.optional(types.literal("QuizQuestion"), "QuizQuestion"),
    id: types.identifier,
    createdAt: types.union(types.undefined, types.frozen()),
    updatedAt: types.union(types.undefined, types.frozen()),
    index: types.union(types.undefined, types.number),
    question: types.union(types.undefined, types.string),
    isYesNoQuestion: types.union(types.undefined, types.boolean),
    options: types.union(types.undefined, types.null, types.array(types.late((): any => QuizQuestionOptionModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class QuizQuestionModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  get index() { return this.__attr(`index`) }
  get question() { return this.__attr(`question`) }
  get isYesNoQuestion() { return this.__attr(`isYesNoQuestion`) }
  options(builder: string | QuizQuestionOptionModelSelector | ((selector: QuizQuestionOptionModelSelector) => QuizQuestionOptionModelSelector) | undefined) { return this.__child(`options`, QuizQuestionOptionModelSelector, builder) }
}
export function selectFromQuizQuestion() {
  return new QuizQuestionModelSelector()
}

export const quizQuestionModelPrimitives = selectFromQuizQuestion().createdAt.updatedAt.index.question.isYesNoQuestion
