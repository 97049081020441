/* eslint-disable  react-native/no-inline-styles */
import React, {FC} from "react"
import {Linking, TextStyle, View, ViewStyle} from "react-native"
import Logo from "../../../../assets/icons/setting/Logo.svg"
import {useHeader} from "../../../utils/useHeader"
import {SettingStackScreenProps} from "../../../navigators/SettingStack"
import {Button, Text} from "../../../components"
import {colorsFamiry} from "../../../theme"
import {$headerTitleText} from "../../../theme/style"
import {translate} from "../../../i18n"
import {observer} from "mobx-react-lite"

interface CompanyScreenProps extends SettingStackScreenProps<"CompanyScreen"> {}

export const CompanyScreen: FC<CompanyScreenProps> = observer(function CompanyScreen({navigation}) {
  useHeader({
    title: translate("companyScreen.about"),
    titleMode: "center",
    leftIconSvg: "back",
    leftIconSize: 20,
    titleStyle: $headerTitleText,
    onLeftPress: () => {
      navigation.goBack()
    },
  })

  return (
    <View style={$container}>
      <View style={$logoContainer}>
        <Logo style={$logo} />
      </View>
      <Text style={$text} size={"B3regular"} text={translate("companyScreen.textOne")} />
      <Text style={$text} size={"B3regular"} text={translate("companyScreen.textTwo")} />
      <Button
        onPress={() => {
          Linking.openURL("https://famiry.ru/")
        }}
        style={$btn}
        text={"Подробнее на сайте"}
      />
    </View>
  )
})
const $container: ViewStyle = {
  flex: 1,
  paddingHorizontal: 16,
}
const $logo: ViewStyle = {
  marginTop: 20,
  marginBottom: 40,
}
const $text: TextStyle = {
  marginBottom: 16,
  color: colorsFamiry.grey140,
}
const $logoContainer: ViewStyle = {
  alignItems: "center",
}
const $btn: ViewStyle = {
  borderWidth: 0,
  backgroundColor: colorsFamiry.lightGrey,
  paddingVertical: 16,
  position: "absolute",
  bottom: 24,
  left: 16,
  right: 16,
}
