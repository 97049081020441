import {observer} from "mobx-react-lite"
import React, {FC, useMemo, useState} from "react"
import {
  Alert,
  Dimensions,
  KeyboardAvoidingView,
  Linking,
  Pressable,
  TextStyle,
  View,
  ViewStyle,
} from "react-native"
import {Button, Text} from "../../components"
import {colorsFamiry} from "../../theme"
import {useHeader} from "../../utils/useHeader"
import Famiry from "../../../assets/icons/Famiry.svg"
import axios from "axios"
import {getEnv} from "mobx-state-tree"
import AsyncStorage from "@react-native-async-storage/async-storage"
import {useStores} from "../../models"
import {translate} from "../../i18n"
import {CodeInput} from "../../components/newComponents/CodeInput"
import {useInterval} from "../../components/newComponents/useInterval"
import {EmailInput} from "../../components/login/EmailInput"
import {IS_WEB} from "../../utils/const"

import {AppStackScreenProps} from "../../navigators"


const BASE_URL = "http://45.8.97.238:3000"
// const discovery = {
//   authorizationEndpoint: "https://famiry.dev/id/oauth/authorize",
//   tokenEndpoint: "https://famiry.dev/id/oauth/token",
// }
// const config = {
//   scopes: [],
//   serviceConfiguration: {
//     authorizationEndpoint: "https://famiry.dev/id/oauth/authorize",
//     tokenEndpoint: "https://famiry.dev/id/oauth/token",
//   },
//   clientId: "980c6713-1494-4cd3-83e2-f2f7006d25bb",
//   clientSecret: "o5mBqzn3M6UlvKnVUsGIawDAVx9dujgK7xli8LTG",
//   redirectUrl: "net.openid.appauth:/oauth2redirect",
// }

interface LoginScreenProps extends AppStackScreenProps<"Login"> {
}

const bodyText = (state: "first" | "email" | "code", phone?: string) => {
  switch (state) {
    case "code":
      return translate("loginScreen.smsCodeSending") + "\n" + phone
    case "email":
      return translate("loginScreen.send")
    case "first":
      return translate("loginScreen.firstText")
  }
}

export const LoginScreen: FC<LoginScreenProps> = observer(function LoginScreen({navigation}) {
  const [inputState, setInputState] = useState<"first" | "email" | "code">("first")
  const [email, setEmail] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [smsCode, setSmsCode] = useState("")
  const [verifyId, setVerifyId] = useState("")
  const [isPinReady, setIsPinReady] = useState(false)
  const [isCodeError, setIsCodeError] = useState(false)
  const [timeLeft, setTimeLeft] = useInterval(0, 1000)
  
  // const [request, response, promptAsync] = useAuthRequest(
  //   {
  //     clientId: "980c6713-1494-4cd3-83e2-f2f7006d25bb",
  //     clientSecret: "o5mBqzn3M6UlvKnVUsGIawDAVx9dujgK7xli8LTG",
  //     redirectUri: makeRedirectUri({
  //       scheme: "your.app",
  //     }),
  //     scopes: [],
  //   },
  //   discovery,
  // )
  // useEffect(() => {
  //   console.log(response)
  //   if (response && (response.type === "success")) {
  //     famiryLogin(response)
  //   }
  // }, [response])
  const maximumCodeLength = 6
  const getSmsCodeBtnPreset = (length: number) => {
    if (length === 6 || timeLeft === 0) return "blue"
    else return "disabled"
  }
  
  const func = () => {
    const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w\w+)+$/
    return reg.test(email)
  }
  
  const isEmail = useMemo(() => func(), [email])
  const getEmailBtnPreset = () => {
    if (isEmail) return "blue"
    else return "disabled"
  }
  const getPhoneBtnText = (length: number) => {
    if (length === 6) return translate("common.continue")
    else return `${translate("loginScreen.codeAgain")} ${timeLeft > 0 ? timeText(timeLeft) : ``}`
  }
  
  const timeText = (time: number) => {
    return `${Math.floor(time / 60)}:${time % 60}`
  }
  
  const {
    queryCurrent_user,
    authenticationStore: {setAuthToken},
  } = useStores()
  const store = useStores()
  
  // async function famiryLogin() {
  //   try {
  //     // const authRes = await authorize(config)
  //     // const callbackRes = await axios.post(BASE_URL + "/auth/famiry/callback", authRes)
  //
  //     if (callbackRes.data.access_token) {
  //       getEnv(store).gqlHttpClient.setHeaders({
  //         Authorization: "bearer " + callbackRes.data.access_token,
  //       })
  //
  //       await queryCurrent_user()
  //       await AsyncStorage.setItem("token", callbackRes.data.access_token)
  //       setAuthToken(callbackRes.data.access_token)
  //     } else {
  //       Alert.alert("Ошибка", "Ошибка авторизации")
  //     }
  //   } catch (error) {
  //     Alert.alert("Ошибка", "Ошибка авторизации")
  //     console.log(error)
  //   }
  // }
  
  const auth = async (code: string, verify_id: string) => {
    const callbackRes = await axios.post(BASE_URL + "/login", {
      verify_id,
      code,
    })
    console.log("callbackRes")
    console.log(callbackRes)
    
    if (callbackRes.data.access_token) {
      getEnv(store).gqlHttpClient.setHeaders({
        Authorization: "bearer " + callbackRes.data.access_token,
      })
      
      await AsyncStorage.setItem("token", callbackRes.data.access_token)
      setAuthToken(callbackRes.data.access_token)
    } else {
      Alert.alert("Ошибка", "Ошибка авторизации")
    }
  }
  
  async function sandCode() {
    try {
      console.log(123)
      setIsLoading(true)
      const callbackRes = await axios.post(BASE_URL + "/auth", {to: email})
      console.log("callbackRes")
      console.log(callbackRes)
      if (callbackRes.data) {
        if (callbackRes.data.code) {
          auth(callbackRes.data.code, callbackRes.data.verify_id).finally(() => {
            setIsLoading(false)
          })
        } else {
          setTimeLeft(Number(callbackRes.data.retry_availbale_in_seconds))
          setIsLoading(false)
          setInputState("code")
          setIsCodeError(false)
          setVerifyId(callbackRes.data.verify_id)
          await AsyncStorage.setItem("verify_id", callbackRes.data.verify_id)
        }
      } else {
        setIsLoading(false)
        Alert.alert("Ошибка", "Ошибка авторизации")
      }
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      if (error.response.status === 400) {
        Alert.alert("Ошибка", "Произошла ошибка попробуйте позже")
      } else if (error.response.status === 429) {
        if (inputState === "code") {
          Alert.alert("Ошибка", "Попробуйте отправку кода позже")
        } else {
          setInputState("code")
          setIsCodeError(false)
          AsyncStorage.getItem("verify_id").then((r) => {
            if (r) setVerifyId(r)
          })
        }
      }
      console.log(error)
    }
  }
  
  
  async function login() {
    try {
      setIsLoading(true)
      if (smsCode.length === 6) {
        auth(smsCode, verifyId).finally(() => {
          setIsLoading(false)
        })
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response.status === 400) {
        setIsCodeError(true)
      }
      console.log(error)
    }
  }
  
  const renderFuncBtn = (state: "first" | "email" | "code") => {
    switch (state) {
      case "first":
        return <View />
      case "email":
        return (
          <Button
            loading={isLoading}
            disabled={!isEmail || !(timeLeft === 0) || isLoading}
            onPress={sandCode}
            style={$btn}
            preset={getEmailBtnPreset()}
            text={`${translate("common.continue")} ${timeLeft > 0 ? timeText(timeLeft) : ``}`}
          />
        )
      case "code":
        return (
          <Button
            loading={isLoading}
            disabled={!(smsCode.length === 6 || timeLeft === 0) || isLoading}
            onPress={login}
            style={$btn}
            preset={getSmsCodeBtnPreset(smsCode.length)}
            text={getPhoneBtnText(smsCode.length)}
          />
        )
    }
  }
  
  useHeader(
    {
      leftIconSvg: inputState === "first" ? null : "back",
      leftIconSize: 20,
      title: inputState === "code" ? translate("common.confirm") : translate("loginScreen.signIn"),
      titleMode: "center",
      onLeftPress: () => {
        if (inputState === "email") {
          setInputState("first")
        } else if (inputState === "code") {
          setEmail("")
          setSmsCode("")
          setInputState("email")
        }
      },
    },
    [inputState],
  )
  
  return (
    <View style={$screenContentContainer}>
      <View style={IS_WEB ? {flex: inputState === "email" ? undefined : 1} : {flex: 1}}>
        <Text style={$text} size={"B2regular"}>
          {bodyText(inputState, email)}
        </Text>
        <Pressable
          onPress={() => {
            if (inputState === "first") {
              setInputState("email")
            }
          }}
        >
          {inputState === "first" && <EmailInput first />}
          {inputState === "email" && <EmailInput setValue={setEmail} value={email} />}
          {inputState === "code" && (
            <CodeInput
              isError={isCodeError}
              code={smsCode}
              setCode={setSmsCode}
              maximumLength={maximumCodeLength}
              setIsPinReady={setIsPinReady}
            />
          )}
        </Pressable>
      </View>
      <KeyboardAvoidingView style={$btnContainer}>{renderFuncBtn(inputState)}</KeyboardAvoidingView>
      {inputState === "first" && (
        <View style={$contentContainer}>
          <View style={$lineTextContainer}>
            <View style={$line} />
            <Text style={$lineText} size={"xs"}>
              {translate("loginScreen.logInUsing")}
            </Text>
          </View>
          <Pressable onPress={() => {
          
          }} style={$famiry}>
            <Famiry />
          </Pressable>
          
          <Text style={$policyText} size={"B3regular"}>
            {translate("loginScreen.policyText") + "\n"}
            <Text
              onPress={() => {
                Linking.openURL("https://famiry.ru/services/policy")
              }}
              style={$policyLinkText}
              size={"B3regular"}
              text={translate("loginScreen.policy") + "\n"}
            />
            {translate("loginScreen.and") + " "}
            <Text
              onPress={() => {
                Linking.openURL("https://famiry.ru/services/terms")
              }}
              style={$policyLinkText}
              size={"B3regular"}
              text={translate("loginScreen.agreement")}
            />
          </Text>
        </View>
      )}
    </View>
  )
})

const $screenContentContainer: ViewStyle = {
  flex: 1,
  marginTop: 14,
  paddingHorizontal: 16,
  width: "100%",
}
const $contentContainer: ViewStyle = {
  alignItems: "center",
  marginBottom: 20,
}
const $famiry: ViewStyle = {
  marginTop: 40,
}
const $btnContainer: ViewStyle = {
  marginVertical: 10,
}

const $lineTextContainer: ViewStyle = {
  width: "100%",
  
  alignItems: "center",
  justifyContent: "center",
}
const $lineText: TextStyle = {
  position: "absolute",
  color: colorsFamiry.grey100,
  backgroundColor: colorsFamiry.white,
  paddingHorizontal: 20,
}

const $policyText: TextStyle = {
  color: colorsFamiry.grey60,
  textAlign: "center",
  marginTop: 80,
}
const $policyLinkText: TextStyle = {
  color: colorsFamiry.grey100,
  textAlign: "center",
}
const $line: ViewStyle = {
  width: "100%",
  height: 1,
  backgroundColor: colorsFamiry.lightGrey,
}
const $btn: ViewStyle = {
  borderRadius: 16,
  marginBottom: 10,
  paddingVertical: 18,
}
const $text: TextStyle = {
  color: colorsFamiry.grey100,
  marginBottom: 20,
}
