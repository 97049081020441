import React, {Dispatch, useEffect, useRef, useState} from "react"
import {Pressable, TextInput, TextStyle, View, ViewStyle} from "react-native"
import {Text} from "../Text"
import {colorsFamiry} from "../../theme"
import {translate} from "../../i18n"

type Props = {
  code: string
  setCode: Dispatch<string>
  maximumLength: number
  setIsPinReady: Dispatch<boolean>
  isError: boolean
}

export function CodeInput({code, setCode, maximumLength, setIsPinReady, isError}: Props) {
  const inputRef = useRef<TextInput>()
  const [isInputBoxFocused, setIsInputBoxFocused] = useState(false)
  useEffect(() => {
    setIsPinReady(code.length === maximumLength)
    return () => {
      setIsPinReady(false)
    }
  }, [code])
  const handleOnPress = () => {
    setIsInputBoxFocused(true)
    inputRef.current.focus()
  }
  
  const handleOnBlur = () => {
    setIsInputBoxFocused(false)
  }
  
  const boxArray = new Array(maximumLength).fill(0)
  
  const boxDigit = (_, index) => {
    const emptyInput = ""
    const digit = code[index] || emptyInput
    const isCurrentValue = index === code.length
    const isLastValue = index === maximumLength - 1
    const isCodeComplete = code.length === maximumLength
    
    const isValueFocused = isCurrentValue || (isLastValue && isCodeComplete)
    
    const $StyledSplitBoxes = isInputBoxFocused && isValueFocused ? $SplitBoxesFocused : $SplitBoxes
    return (
      <View style={$StyledSplitBoxes} key={index}>
        <Text style={$SplitBoxText}>{digit}</Text>
      </View>
    )
  }
  
  return (
    <View>
      <View style={$OTPInputContainer}>
        <Pressable onPress={handleOnPress} style={$SplitOTPBoxesContainer}>
          {boxArray.map(boxDigit)}
        </Pressable>
        <TextInput
          style={$TextInputHidden}
          ref={inputRef}
          value={code}
          maxLength={maximumLength}
          onBlur={handleOnBlur}
          keyboardType={"numeric"}
          onChangeText={setCode}
        />
      </View>
      {isError && (
        <Text style={$errorText} size={"B3regular"} text={translate("loginScreen.codeError")} />
      )}
    </View>
  )
}

const $OTPInputContainer: ViewStyle = {
  justifyContent: "center",
  alignItems: "center",
  marginBottom: 8,
}
const $TextInputHidden: ViewStyle = {
  width: 300,
  borderRadius: 5,
  padding: 15,
  position: "absolute",
  opacity: 0,
  // @ts-ignore
  outlineWidth: 0,
  outline: "none",
}
const $SplitOTPBoxesContainer: ViewStyle = {
  width: "100%",
  flexDirection: "row",
}
const $SplitBoxes: ViewStyle = {
  backgroundColor: colorsFamiry.lightGrey,
  
  borderRadius: 12,
  padding: 9,
  minWidth: 40,
  height: 42,
  marginRight: 11,
}
const $SplitBoxText: TextStyle = {
  fontSize: 20,
  textAlign: "center",
  color: colorsFamiry.black,
}
const $errorText: TextStyle = {
  color: colorsFamiry.error,
}
const $SplitBoxesFocused: ViewStyle = {
  borderRadius: 12,
  padding: 9,
  minWidth: 40,
  height: 42,
  marginRight: 11,
  backgroundColor: colorsFamiry.grey60,
}
