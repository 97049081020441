/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { ArticleModel, ArticleModelType } from "./ArticleModel"
import { ArticleModelSelector } from "./ArticleModel.base"
import { RootStoreType } from "./index"


/**
 * ArticleCategoryBase
 * auto generated base class for the model ArticleCategoryModel.
 */
export const ArticleCategoryModelBase = ModelBase
  .named('ArticleCategory')
  .props({
    __typename: types.optional(types.literal("ArticleCategory"), "ArticleCategory"),
    id: types.identifier,
    createdAt: types.union(types.undefined, types.frozen()),
    updatedAt: types.union(types.undefined, types.frozen()),
    name: types.union(types.undefined, types.string),
    articles: types.union(types.undefined, types.array(types.late((): any => ArticleModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class ArticleCategoryModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  get name() { return this.__attr(`name`) }
  articles(builder: string | ArticleModelSelector | ((selector: ArticleModelSelector) => ArticleModelSelector) | undefined) { return this.__child(`articles`, ArticleModelSelector, builder) }
}
export function selectFromArticleCategory() {
  return new ArticleCategoryModelSelector()
}

export const articleCategoryModelPrimitives = selectFromArticleCategory().createdAt.updatedAt.name
