import React, {FC, useEffect, useState} from "react"
import {ActivityIndicator, Alert, FlatList, Linking, RefreshControl, View, ViewStyle} from "react-native"
import {useStores} from "../../models"
import {useHeader} from "../../utils/useHeader"
import {Card} from "../../components/newComponents/Card"
import {cardCollectionModelSelector, CardCollectionModelType, cardModelSelector} from "../../models/generated"
import {translate} from "../../i18n"
import {SwipeModal} from "../../components/newComponents/SwipeModal"
import {observer} from "mobx-react-lite"
import {AppStackScreenProps} from "../../navigators"
import {$headerTitleText} from "../../theme/style"
import AsyncStorage from "@react-native-async-storage/async-storage"
import {MainModal} from "../../components/newComponents/MainModal"
import {requestUserPermission} from "../../utils/notifications"
import {SpecDeck} from "../../components/newComponents/SpecDeck"
import {IS_WEB} from "../../utils/const"
import axios from "axios"

import cookie, {useCookies} from "react-cookie"

interface GameScreenProps extends AppStackScreenProps<"BottomTab"> {
}

export const GamesScreen: FC<GameScreenProps> = observer(function GameScreen({navigation}) {
  const {queryCardCollections, queryCardCollection, getCardCollections} = useStores()
  const {setLoading, loading} = useStores().authenticationStore
  const [isLoading, setIsLoading] = useState(false)
  const [selectCardCollection, setSelectCardCollection] = useState<CardCollectionModelType | null>(
    null,
  )
  const [cookies, setCookie, removeCookie] = useCookies([""])
  const [refreshing, setRefreshing] = useState(false)
  const [isBlockModal, setIsBlockModal] = useState(false)
  const [isContinueModal, setIsContinueModal] = useState(false)
  const [isDemoModal, setIsDemoModal] = useState(false)
  const [isFavoriteModal, setIsFavoriteModal] = useState(false)
  const [isSwipeModal, setIsSwipeModal] = useState(false)
  const getData = async () => {
    try {
      await queryCardCollections({}, cardCollectionModelSelector)
    } catch (error) {
      console.log(error)
    }
  }
  const onRefresh = () => {
    getData().finally(() => {
      setRefreshing(false)
    })
  }
  
  
  useHeader({
    title: translate("bottomTab.games"),
    titleMode: "center",
    titleStyle: $headerTitleText,
    onRightPress: () => {
      setIsSwipeModal(true)
    },
    rightIconSvg: "question",
    rightIconSize: 25,
  })
  
  useEffect(() => {
    setLoading(true)
    
    getData().finally(() => {
      setLoading(false)
    })
  }, [])
  
  
  const onPress = async (cardCollection: CardCollectionModelType) => {
    setLoading(true)
    await queryCardCollection({id: cardCollection.id}, cardModelSelector).then(collection => {
      if (collection) {
        AsyncStorage.getItem(collection.cardCollection.id).then((res) => {
          setLoading(false)
          if (res) {
            if (IS_WEB) {
              setSelectCardCollection(collection.cardCollection)
              setIsContinueModal(true)
            } else {
              modal(collection.cardCollection)
            }
          } else {
            navigation.navigate("GamePreviewScreen", {cardCollection: collection.cardCollection})
          }
        })
      } else {
        setLoading(false)
      }
    })
    
  }
  const onBlockPress = (cardCollection: CardCollectionModelType) => {
    setSelectCardCollection(cardCollection)
    setIsBlockModal(true)
  }
  const onDemoPress = (cardCollection: CardCollectionModelType) => {
    setSelectCardCollection(cardCollection)
    setIsDemoModal(true)
  }
  const onBlockPressSubscribe = async () => {
    await requestUserPermission()
    setIsBlockModal(false)
  }
  const onBlockPressCancel = () => {
    setIsBlockModal(false)
  }
  const onDemoPressCancel = () => {
    setIsDemoModal(false)
  }
  
  const onContinuePressCancel = () => {
    setIsContinueModal(false)
  }
  const onPressModalAgain = (cardCollection) => {
    setIsContinueModal(false)
    navigation.navigate("GamePreviewScreen", {cardCollection})
  }
  
  const onPressModalContinue = (cardCollection) => {
    setIsContinueModal(false)
    AsyncStorage.getItem(cardCollection.id).then((res) => {
      navigation.navigate("GameScreen", {
        cardCollection,
        selectedCard: res,
      })
    })
  }
  const modal = (cardCollection) => {
    return Alert.alert(
      "Хотите продолжить?",
      "Вы недавно играли в эту игру\nи не завершили",
      [
        {
          text: "Начать заново",
          onPress: () => onPressModalAgain(cardCollection),
        },
        {
          text: "Продолжить",
          onPress: () => onPressModalContinue(cardCollection),
        },
      ],
      {cancelable: true},
    )
  }
  
  if (isLoading || loading) {
    return (
      <View style={$loader}>
        <ActivityIndicator color={"#5653EC"} />
      </View>
    )
  }
  
  return (
    <View style={$container}>
      <FlatList
        refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
        showsVerticalScrollIndicator={false}
        data={getCardCollections}
        ListHeaderComponent={
          <View style={$specDeckContainer}>
            <SpecDeck key={1} navigation={navigation} setIsModal={setIsFavoriteModal} type={"favorites"}
                      style={$specDeck} />
            <SpecDeck key={2} navigation={navigation} type={"popular"} />
          </View>
        }
        renderItem={(item) => (
          <>
            
            {item.item[1].status !== undefined && <Card
              onDemoPress={onDemoPress}
              onPress={onPress}
              onBlockPress={onBlockPress}
              navigation={navigation}
              cardCollection={item.item[1]}
            />}
          </>
        )}
      />
      <MainModal
        isModal={isDemoModal}
        setIsModal={setIsDemoModal}
        color={selectCardCollection && "#" + selectCardCollection.backgroundColor}
        bodyText={`${translate("mainModal.demoModal.titleOne")}  ${translate("mainModal.demoModal.titleTwo")}`}
        titleText={selectCardCollection ? selectCardCollection.title : ""}
        imageUrl={selectCardCollection && selectCardCollection.img}
        closeOnPress={onDemoPressCancel}
        button={{
          oneBtnText: translate("mainModal.demoModal.oneBtn"),
          twoBtnText: translate("mainModal.demoModal.twoBtn"),
          oneBtnOnPress: onBlockPressSubscribe,
          twoBtnOnPress: () => {
            if (selectCardCollection)
              navigation.navigate("GamePreviewScreen", {
                cardCollection: selectCardCollection,
                demo: true,
              })
            setIsDemoModal(false)
          },
        }}
      />
      <MainModal
        isModal={isBlockModal}
        setIsModal={setIsBlockModal}
        color={selectCardCollection && "#" + selectCardCollection.backgroundColor}
        bodyText={translate("mainModal.blockCardModal.body")}
        titleText={selectCardCollection ? selectCardCollection.title : ""}
        imageUrl={selectCardCollection && selectCardCollection.img}
        closeOnPress={onBlockPressCancel}
        button={{
          oneBtnText: translate("mainModal.blockCardModal.oneBtn"),
          twoBtnText: translate("mainModal.blockCardModal.twoBtn"),
          oneBtnOnPress: onBlockPressSubscribe,
          twoBtnOnPress: onBlockPressCancel,
        }}
      />
      <MainModal
        isModal={isContinueModal}
        setIsModal={setIsContinueModal}
        color={selectCardCollection && "#" + selectCardCollection.backgroundColor}
        bodyText={translate("modalContinue.body")}
        titleText={translate("modalContinue.title")}
        imageUrl={selectCardCollection && selectCardCollection.img}
        closeOnPress={onContinuePressCancel}
        button={{
          oneBtnText: translate("modalContinue.continue"),
          twoBtnText: translate("modalContinue.again"),
          oneBtnOnPress: () => {
            onPressModalContinue(selectCardCollection)
          },
          twoBtnOnPress: () => {
            onPressModalAgain(selectCardCollection)
          },
        }}
      />
      <MainModal
        isModal={isFavoriteModal}
        setIsModal={setIsFavoriteModal}
        bodyText={"У Вас нет избранных вопросов"}
        button={{
          oneBtnText: translate("modalContinue.continue"),
          oneBtnOnPress: () => {
            setIsFavoriteModal(false)
          },
        }}
      />
      <SwipeModal
        isModal={isSwipeModal}
        bodyText={translate("swipeModal.body")}
        secondBodyText={translate("swipeModal.secondBody")}
        setIsModal={setIsSwipeModal}
      />
    </View>
  )
})

const $container: ViewStyle = {
  flex: 1,
  paddingHorizontal: 16,
}
const $loader: ViewStyle = {
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
}
const $specDeck: ViewStyle = {
  marginRight: 12,
}
const $specDeckContainer: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-between",
}
