import {Instance, SnapshotOut, types} from "mobx-state-tree"
import AsyncStorage from "@react-native-async-storage/async-storage"
import {CardCollectionModel} from "./generated"

export const AuthenticationStoreModel = types
  .model("AuthenticationStore")
  .props({
    authToken: types.maybe(types.string),
    authEmail: "",
    authPassword: "",
    loading: false,
    cardCollectionsArray: types.optional(types.array(CardCollectionModel), []),
  })
  .views((store) => ({
    get isAuthenticated() {
      return !!store.authToken
    },
    get validationErrors() {
      return {
        authEmail: (function() {
          if (store.authEmail.length === 0) return "не может быть пустым"
          if (store.authEmail.length < 6) return "должно быть не менее 6 символов"
          if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(store.authEmail))
            return "должен быть действительный адрес электронной почты"
          return ""
        })(),
        authPassword: (function() {
          if (store.authPassword.length === 0) return "не может быть пустым"
          if (store.authPassword.length < 6) return "должно быть не менее 6 символов"
          return ""
        })(),
      }
    },
  }))
  .actions((store) => ({
    setAuthToken(value?: string) {
      store.authToken = value
    },
    setLoading(value?: boolean) {
      store.loading = value
    },
    setAuthEmail(value: string) {
      store.authEmail = value.replace(/ /g, "")
    },
    setAuthPassword(value: string) {
      store.authPassword = value.replace(/ /g, "")
    },
    logout() {
      store.authToken = undefined
      store.authEmail = ""
      store.authPassword = ""
      AsyncStorage.setItem("token", "")
    },
  }))

export interface AuthenticationStore extends Instance<typeof AuthenticationStoreModel> {
}

export interface AuthenticationStoreSnapshot extends SnapshotOut<typeof AuthenticationStoreModel> {
}

// @demo remove-file
