import React, {FC} from "react"
import {TextStyle, View, ViewStyle} from "react-native"
import {colorsFamiry} from "../../theme"
import {Text} from "../../components"
import {useHeader} from "../../utils/useHeader"
import Shop from "../../../assets/icons/imgShop.svg"
import {$headerTitleText} from "../../theme/style"
import {translate} from "../../i18n"
import {observer} from "mobx-react-lite"

export const StoreScreen: FC = observer(function StoreScreen() {
  useHeader({
    title: translate("storeScreen.store"),
    titleMode: "center",
    titleStyle: $headerTitleText,
  })

  return (
    <View style={$container}>
      <Shop />
      <Text size={"H2bold"} style={$textOne} text={translate("storeScreen.void")} />
      <Text size={"B1regular"} style={$textTwo} text={translate("storeScreen.text")} />
    </View>
  )
})
const $container: ViewStyle = {
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
  paddingHorizontal: 20,
}
const $textOne: TextStyle = {
  marginTop: 24,
  textAlign: "center",
  color: colorsFamiry.black,
}
const $textTwo: TextStyle = {
  marginTop: 16,
  textAlign: "center",
  color: colorsFamiry.grey60,
}
