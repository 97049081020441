/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { ObservableMap } from "mobx"
import { types } from "mobx-state-tree"
import { MSTGQLStore, configureStoreMixin, QueryOptions, withTypedRefs } from "mst-gql"

import { CardCollectionTagModel, CardCollectionTagModelType } from "./CardCollectionTagModel"
import { cardCollectionTagModelPrimitives, CardCollectionTagModelSelector } from "./CardCollectionTagModel.base"
import { CardCollectionSubscribeModel, CardCollectionSubscribeModelType } from "./CardCollectionSubscribeModel"
import { cardCollectionSubscribeModelPrimitives, CardCollectionSubscribeModelSelector } from "./CardCollectionSubscribeModel.base"
import { CardCollectionModel, CardCollectionModelType } from "./CardCollectionModel"
import { cardCollectionModelPrimitives, CardCollectionModelSelector } from "./CardCollectionModel.base"
import { CardDislikeModel, CardDislikeModelType } from "./CardDislikeModel"
import { cardDislikeModelPrimitives, CardDislikeModelSelector } from "./CardDislikeModel.base"
import { CardModel, CardModelType } from "./CardModel"
import { cardModelPrimitives, CardModelSelector } from "./CardModel.base"
import { CardLikeModel, CardLikeModelType } from "./CardLikeModel"
import { cardLikeModelPrimitives, CardLikeModelSelector } from "./CardLikeModel.base"
import { QuizResultOptionModel, QuizResultOptionModelType } from "./QuizResultOptionModel"
import { quizResultOptionModelPrimitives, QuizResultOptionModelSelector } from "./QuizResultOptionModel.base"
import { QuizModel, QuizModelType } from "./QuizModel"
import { quizModelPrimitives, QuizModelSelector } from "./QuizModel.base"
import { QuizQuestionModel, QuizQuestionModelType } from "./QuizQuestionModel"
import { quizQuestionModelPrimitives, QuizQuestionModelSelector } from "./QuizQuestionModel.base"
import { QuizQuestionOptionModel, QuizQuestionOptionModelType } from "./QuizQuestionOptionModel"
import { quizQuestionOptionModelPrimitives, QuizQuestionOptionModelSelector } from "./QuizQuestionOptionModel.base"
import { QuizUserAnswerModel, QuizUserAnswerModelType } from "./QuizUserAnswerModel"
import { quizUserAnswerModelPrimitives, QuizUserAnswerModelSelector } from "./QuizUserAnswerModel.base"
import { UserModel, UserModelType } from "./UserModel"
import { userModelPrimitives, UserModelSelector } from "./UserModel.base"
import { ArticleCategoryModel, ArticleCategoryModelType } from "./ArticleCategoryModel"
import { articleCategoryModelPrimitives, ArticleCategoryModelSelector } from "./ArticleCategoryModel.base"
import { ArticleModel, ArticleModelType } from "./ArticleModel"
import { articleModelPrimitives, ArticleModelSelector } from "./ArticleModel.base"
import { ToggleLikeModel, ToggleLikeModelType } from "./ToggleLikeModel"
import { toggleLikeModelPrimitives, ToggleLikeModelSelector } from "./ToggleLikeModel.base"



export type ResultOptionInputSearchByInterval = {
  quiz: string
  intervalStart: number
  intervalEnd: number
}
export type UpdateUserFcmTokenInput = {
  exampleField?: (number | null)
  fcm_token: string
}
export type CreateCardCollectionInput = {
  title: string
  description: string
  howToPlay: string
  passageTime: number
  price?: (number | null)
  freeToUse: boolean
  backgroundColor: string
  cards: CreateCardInput[]
}
export type CreateCardInput = {
  title: string
  question: string
}
export type ToggleCardLikeInput = {
  card: string
}
export type CreateCardDislikeInput = {
  card: string
  user: string
}
export type CreateQuizUserAnswerInput = {
  user: string
  questionOption: string
}
export type UpdateQuizUserAnswerInput = {
  user?: (string | null)
  questionOption?: (string | null)
  id: string
}
export type CreateCardCollectionSubscribeInput = {
  collection: string
}
/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  users: ObservableMap<string, UserModelType>,
  cardCollections: ObservableMap<string, CardCollectionModelType>,
  cardCollectionTags: ObservableMap<string, CardCollectionTagModelType>
}


/**
* Enums for the names of base graphql actions
*/
export enum RootStoreBaseQueries {
queryUsers="queryUsers",
queryUser="queryUser",
queryCurrent_user="queryCurrent_user",
queryFavoriteCards="queryFavoriteCards",
queryArticles="queryArticles",
queryArticle="queryArticle",
queryCardCollections="queryCardCollections",
queryCardCollection="queryCardCollection",
queryPopularCards="queryPopularCards",
queryQuizzes="queryQuizzes",
queryQuiz="queryQuiz",
queryQuizResult="queryQuizResult",
queryArticleCategories="queryArticleCategories",
queryArticleCategory="queryArticleCategory"
}
export enum RootStoreBaseMutations {
mutateUpdateUserFcmToken="mutateUpdateUserFcmToken",
mutateCreateCardCollection="mutateCreateCardCollection",
mutateToggleCardLike="mutateToggleCardLike",
mutateCreateCardDislike="mutateCreateCardDislike",
mutateRemoveCardDislike="mutateRemoveCardDislike",
mutateCreateQuizUserAnswer="mutateCreateQuizUserAnswer",
mutateUpdateQuizUserAnswer="mutateUpdateQuizUserAnswer",
mutateCreateCardCollectionSubscriber="mutateCreateCardCollectionSubscriber"
}

/**
* Store, managing, among others, all the objects received through graphQL
*/
export const RootStoreBase = withTypedRefs<Refs>()(MSTGQLStore
  .named("RootStore")
  .extend(configureStoreMixin([['CardCollectionTag', () => CardCollectionTagModel], ['CardCollectionSubscribe', () => CardCollectionSubscribeModel], ['CardCollection', () => CardCollectionModel], ['CardDislike', () => CardDislikeModel], ['Card', () => CardModel], ['CardLike', () => CardLikeModel], ['QuizResultOption', () => QuizResultOptionModel], ['Quiz', () => QuizModel], ['QuizQuestion', () => QuizQuestionModel], ['QuizQuestionOption', () => QuizQuestionOptionModel], ['QuizUserAnswer', () => QuizUserAnswerModel], ['User', () => UserModel], ['ArticleCategory', () => ArticleCategoryModel], ['Article', () => ArticleModel], ['ToggleLike', () => ToggleLikeModel]], ['User', 'CardCollection', 'CardCollectionTag'], "js"))
  .props({
    users: types.optional(types.map(types.late((): any => UserModel)), {}),
    cardCollections: types.optional(types.map(types.late((): any => CardCollectionModel)), {}),
    cardCollectionTags: types.optional(types.map(types.late((): any => CardCollectionTagModel)), {})
  })
  .actions(self => ({
    queryUsers(variables?: {  }, resultSelector: string | ((qb: UserModelSelector) => UserModelSelector) = userModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ users: UserModelType[]}>(`query users { users {
        ${typeof resultSelector === "function" ? resultSelector(new UserModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryUser(variables: { id: string }, resultSelector: string | ((qb: UserModelSelector) => UserModelSelector) = userModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ user: UserModelType}>(`query user($id: ID!) { user(id: $id) {
        ${typeof resultSelector === "function" ? resultSelector(new UserModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryCurrent_user(variables?: {  }, resultSelector: string | ((qb: UserModelSelector) => UserModelSelector) = userModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ current_user: UserModelType}>(`query current_user { current_user {
        ${typeof resultSelector === "function" ? resultSelector(new UserModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryFavoriteCards(variables?: {  }, resultSelector: string | ((qb: CardModelSelector) => CardModelSelector) = cardModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ favoriteCards: CardModelType[]}>(`query favoriteCards { favoriteCards {
        ${typeof resultSelector === "function" ? resultSelector(new CardModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryArticles(variables?: {  }, resultSelector: string | ((qb: ArticleModelSelector) => ArticleModelSelector) = articleModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ articles: ArticleModelType[]}>(`query articles { articles {
        ${typeof resultSelector === "function" ? resultSelector(new ArticleModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryArticle(variables: { id: string }, resultSelector: string | ((qb: ArticleModelSelector) => ArticleModelSelector) = articleModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ article: ArticleModelType}>(`query article($id: ID!) { article(id: $id) {
        ${typeof resultSelector === "function" ? resultSelector(new ArticleModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryCardCollections(variables?: {  }, resultSelector: string | ((qb: CardCollectionModelSelector) => CardCollectionModelSelector) = cardCollectionModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ cardCollections: CardCollectionModelType[]}>(`query cardCollections { cardCollections {
        ${typeof resultSelector === "function" ? resultSelector(new CardCollectionModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryCardCollection(variables: { id: string }, resultSelector: string | ((qb: CardCollectionModelSelector) => CardCollectionModelSelector) = cardCollectionModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ cardCollection: CardCollectionModelType}>(`query cardCollection($id: ID!) { cardCollection(id: $id) {
        ${typeof resultSelector === "function" ? resultSelector(new CardCollectionModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryPopularCards(variables?: {  }, resultSelector: string | ((qb: CardModelSelector) => CardModelSelector) = cardModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ popularCards: CardModelType[]}>(`query popularCards { popularCards {
        ${typeof resultSelector === "function" ? resultSelector(new CardModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryQuizzes(variables?: {  }, resultSelector: string | ((qb: QuizModelSelector) => QuizModelSelector) = quizModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ quizzes: QuizModelType[]}>(`query quizzes { quizzes {
        ${typeof resultSelector === "function" ? resultSelector(new QuizModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryQuiz(variables: { id: string }, resultSelector: string | ((qb: QuizModelSelector) => QuizModelSelector) = quizModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ quiz: QuizModelType}>(`query quiz($id: ID!) { quiz(id: $id) {
        ${typeof resultSelector === "function" ? resultSelector(new QuizModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryQuizResult(variables: { input: ResultOptionInputSearchByInterval }, resultSelector: string | ((qb: QuizResultOptionModelSelector) => QuizResultOptionModelSelector) = quizResultOptionModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ quizResult: QuizResultOptionModelType}>(`query quizResult($input: ResultOptionInputSearchByInterval!) { quizResult(input: $input) {
        ${typeof resultSelector === "function" ? resultSelector(new QuizResultOptionModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryArticleCategories(variables?: {  }, resultSelector: string | ((qb: ArticleCategoryModelSelector) => ArticleCategoryModelSelector) = articleCategoryModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ articleCategories: ArticleCategoryModelType[]}>(`query articleCategories { articleCategories {
        ${typeof resultSelector === "function" ? resultSelector(new ArticleCategoryModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryArticleCategory(variables: { id: string }, resultSelector: string | ((qb: ArticleCategoryModelSelector) => ArticleCategoryModelSelector) = articleCategoryModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ articleCategory: ArticleCategoryModelType}>(`query articleCategory($id: ID!) { articleCategory(id: $id) {
        ${typeof resultSelector === "function" ? resultSelector(new ArticleCategoryModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    mutateUpdateUserFcmToken(variables: { updateUserFcmToken: UpdateUserFcmTokenInput }, resultSelector: string | ((qb: UserModelSelector) => UserModelSelector) = userModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ updateUserFcmToken: UserModelType}>(`mutation updateUserFcmToken($updateUserFcmToken: UpdateUserFcmTokenInput!) { updateUserFcmToken(updateUserFcmToken: $updateUserFcmToken) {
        ${typeof resultSelector === "function" ? resultSelector(new UserModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateCreateCardCollection(variables: { createCardCollectionInput: CreateCardCollectionInput }, resultSelector: string | ((qb: CardCollectionModelSelector) => CardCollectionModelSelector) = cardCollectionModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ createCardCollection: CardCollectionModelType}>(`mutation createCardCollection($createCardCollectionInput: CreateCardCollectionInput!) { createCardCollection(createCardCollectionInput: $createCardCollectionInput) {
        ${typeof resultSelector === "function" ? resultSelector(new CardCollectionModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateToggleCardLike(variables: { input: ToggleCardLikeInput }, resultSelector: string | ((qb: ToggleLikeModelSelector) => ToggleLikeModelSelector) = toggleLikeModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ toggleCardLike: ToggleLikeModelType}>(`mutation toggleCardLike($input: ToggleCardLikeInput!) { toggleCardLike(input: $input) {
        ${typeof resultSelector === "function" ? resultSelector(new ToggleLikeModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateCreateCardDislike(variables: { createCardDislikeInput: CreateCardDislikeInput }, resultSelector: string | ((qb: CardDislikeModelSelector) => CardDislikeModelSelector) = cardDislikeModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ createCardDislike: CardDislikeModelType}>(`mutation createCardDislike($createCardDislikeInput: CreateCardDislikeInput!) { createCardDislike(createCardDislikeInput: $createCardDislikeInput) {
        ${typeof resultSelector === "function" ? resultSelector(new CardDislikeModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateRemoveCardDislike(variables: { id: string }, resultSelector: string | ((qb: CardDislikeModelSelector) => CardDislikeModelSelector) = cardDislikeModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ removeCardDislike: CardDislikeModelType}>(`mutation removeCardDislike($id: ID!) { removeCardDislike(id: $id) {
        ${typeof resultSelector === "function" ? resultSelector(new CardDislikeModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateCreateQuizUserAnswer(variables: { createQuizUserAnswerInput: CreateQuizUserAnswerInput }, resultSelector: string | ((qb: QuizUserAnswerModelSelector) => QuizUserAnswerModelSelector) = quizUserAnswerModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ createQuizUserAnswer: QuizUserAnswerModelType}>(`mutation createQuizUserAnswer($createQuizUserAnswerInput: CreateQuizUserAnswerInput!) { createQuizUserAnswer(createQuizUserAnswerInput: $createQuizUserAnswerInput) {
        ${typeof resultSelector === "function" ? resultSelector(new QuizUserAnswerModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateUpdateQuizUserAnswer(variables: { updateQuizUserAnswerInput: UpdateQuizUserAnswerInput }, resultSelector: string | ((qb: QuizUserAnswerModelSelector) => QuizUserAnswerModelSelector) = quizUserAnswerModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ updateQuizUserAnswer: QuizUserAnswerModelType}>(`mutation updateQuizUserAnswer($updateQuizUserAnswerInput: UpdateQuizUserAnswerInput!) { updateQuizUserAnswer(updateQuizUserAnswerInput: $updateQuizUserAnswerInput) {
        ${typeof resultSelector === "function" ? resultSelector(new QuizUserAnswerModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateCreateCardCollectionSubscriber(variables: { createCardCollectionSubscriberInput: CreateCardCollectionSubscribeInput }, resultSelector: string | ((qb: CardCollectionSubscribeModelSelector) => CardCollectionSubscribeModelSelector) = cardCollectionSubscribeModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ createCardCollectionSubscriber: CardCollectionSubscribeModelType}>(`mutation createCardCollectionSubscriber($createCardCollectionSubscriberInput: CreateCardCollectionSubscribeInput!) { createCardCollectionSubscriber(createCardCollectionSubscriberInput: $createCardCollectionSubscriberInput) {
        ${typeof resultSelector === "function" ? resultSelector(new CardCollectionSubscribeModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
  })))
