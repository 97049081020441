import {Image, ImageStyle, Pressable, TextStyle, View, ViewStyle} from "react-native"
import {Text} from "../Text"
import Watch from "../../../assets/icons/clockNew.svg"
import Cards from "../../../assets/icons/cardIcon.svg"
import React, {FC} from "react"
import Lock from "../../../assets/icons/cards/LockNew.svg"
import Rub from "../../../assets/icons/cards/rub.svg"
import {colorsFamiry} from "../../theme"
import {CardCollectionModelType} from "../../models/generated"
import {StackNavigationProp} from "@react-navigation/stack"
import {AppStackParamList} from "../../navigators"
import {observer} from "mobx-react-lite"
import {declOfNum} from "../../utils/textHelp"
import {BASE_URL, IS_WEB} from "../../utils/const"

export type CardProps = {
  cardCollection: CardCollectionModelType
  navigation: StackNavigationProp<AppStackParamList>
  onPress: (cardCollection: CardCollectionModelType) => void
  onBlockPress: (cardCollection: CardCollectionModelType) => void
  onDemoPress: (cardCollection: CardCollectionModelType) => void
}

export const Card: FC<CardProps> = observer(function Card({
                                                            cardCollection,
                                                            onPress,
                                                            onBlockPress,
                                                            onDemoPress,
                                                          }) {
  const statusObj = {
    0: {
      onClick: () => {
        if (cardCollection.freeToUse) {
          
          onPress(cardCollection)
        } else {
          onDemoPress(cardCollection)
        }
      },
      icon: cardCollection.freeToUse ? null : Rub,
    },
    1: {
      onClick: () => {
        onBlockPress(cardCollection)
      },
      icon: Lock,
      text: "Скоро будет доступно",
    },
    2: {
      onClick: () => {
        onBlockPress(cardCollection)
      },
      icon: Lock,
      text: "Скоро будет доступно",
    },
  }
  
  const onClick = () => {
    console.log(1232)
    if (cardCollection?.status !== undefined) statusObj[cardCollection?.status].onClick()
  }
  const Icon = cardCollection?.status !== undefined ? statusObj[cardCollection?.status].icon : null
  
  return (
    <Pressable
      onPress={onClick}
      style={$container}>
      <View style={cardCollection?.status !== 0 && $blur} />
      <View
        style={[$cardContainer, {backgroundColor: "#" + cardCollection?.backgroundColor}]}
      >
        <Text size={"H2bold"} style={$titleText}>
          {cardCollection?.title}
        </Text>
        <View style={$tagContainer}>
          <View style={$tagTextContainer}>
            <Text size={"B3bold"} style={$tagText}>
              {cardCollection?.tag?.title}
            </Text>
          </View>
        </View>
        <View style={$iconContainer}>
          <Cards style={$icon} />
          <Text size={"B3regular"} style={$bodyText}>
            {cardCollection?.cardsCount +
              " " +
              declOfNum(cardCollection?.cardsCount, ["карточка", "карточки", "карточек"])}
          </Text>
        </View>
        <View style={$textTimeContainer}>
          <Watch style={$icon} />
          <Text size={"B3regular"} style={$bodyText}>
            {cardCollection?.passageTime +
              " " +
              declOfNum(cardCollection?.passageTime, ["минута", "минуты", "минут"])}
          </Text>
        </View>
        <Image style={$image} resizeMode={"stretch"} source={{uri: BASE_URL + cardCollection?.img}} />
      </View>
      {cardCollection.status !== undefined && <>
        {cardCollection.status !== 0 ?
          <View style={$lockContainer}>
            <Icon />
            <Text style={$lockText}>
              {cardCollection?.status && statusObj[cardCollection?.status].text}
            </Text>
          </View>
          :
          <>{Icon && <Icon style={$lock} />}</>
        }
      </>}
    </Pressable>
  )
})

const $cardContainer: ViewStyle = {
  borderRadius: 16,
  paddingHorizontal: 20,
  paddingTop: 20,
  paddingBottom: 35,
  
}
const $blur: ViewStyle = {
  position: "absolute",
  borderRadius: 16,
  backgroundColor: "rgba(255,255,255,0.49)",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 1,
}
const $container: ViewStyle = {
  marginBottom: 16,
}
const $lockContainer: ViewStyle = {
  position: "absolute",
  backgroundColor: "#FFF",
  borderRadius: 32,
  flexDirection: "row",
  paddingVertical: 8,
  paddingHorizontal: 12,
  bottom: 16,
  right: 16,
  zIndex: 2,
}

const $iconContainer: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
  marginBottom: 12,
}
const $textTimeContainer: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
}
const $lock: ViewStyle = {
  position: "absolute",
  right: 20,
  top: 20,
  zIndex: 1,
}
const $image: ImageStyle = {
  position: "absolute",
  bottom: 0,
  right: 0,
  width: IS_WEB ? "56%" : "64%",
  height: IS_WEB ? "75%" : "100%",
  borderBottomRightRadius: 16,
}

const $icon: ViewStyle = {
  marginRight: 5,
}

const $titleText: TextStyle = {
  color: colorsFamiry.black,
  marginBottom: 12,
}
const $bodyText: TextStyle = {
  color: colorsFamiry.grey100,
}
const $lockText: TextStyle = {
  marginLeft: 8,
  color: colorsFamiry.accent,
  fontWeight: "500",
}
const $tagText: TextStyle = {
  paddingBottom: 8,
  paddingTop: 5,
  
  color: colorsFamiry.black,
}
const $tagContainer: ViewStyle = {
  flexDirection: "row",
  marginBottom: 16,
}
const $tagTextContainer: ViewStyle = {
  paddingHorizontal: 12,
  borderRadius: 32,
  backgroundColor: colorsFamiry.white,
}
