import {observer} from "mobx-react-lite"
import {BackHandler, View, ViewStyle} from "react-native"
import {useHeader} from "../utils/useHeader"
import React, {FC, useEffect, useState} from "react"
import {AppStackScreenProps} from "../navigators"
import {SwipeModal} from "../components/newComponents/SwipeModal"
import {SwipeCard} from "../components/newComponents/SwipeCard"
import {$headerTitleText} from "../theme/style"
import AsyncStorage from "@react-native-async-storage/async-storage"
import {BASE_URL, MAX_WIDTH} from "../utils/const"
import {useStores} from "../models"

interface GameScreenProps extends AppStackScreenProps<"GameScreen"> {
}

export const GameScreen: FC<GameScreenProps> = observer(function GameScreen({route, navigation}) {
  const [isSwipeModal, setIsSwipeModal] = useState(false)
  const [count, setCount] = useState(1)
  const data =
    route.params.cardCollection &&
    route.params.cardCollection.howToPlay.split(";").filter((item) => item !== "")
  
  const backgroundColor =
    "#" + (route.params.cardCollection ? route.params.cardCollection.backgroundColor : "FFF")
  
  const endPress = () => {
    navigation.navigate("BottomTab")
  }
  
  const questionPress = () => {
    setIsSwipeModal(true)
  }
  
  const specDeckTitle = {
    favorites: "Избранные",
    popular: "Популярные",
  }
  
  const handleBackButtonClick = () => {
    navigation.navigate("BottomTab")
    return true
  }
  const {getCardCollectionsMap} = useStores()
  
  useEffect(() => {
    BackHandler.addEventListener("hardwareBackPress", handleBackButtonClick)
    return () => {
      BackHandler.removeEventListener("hardwareBackPress", handleBackButtonClick)
    }
  }, [])
  
  useEffect(() => {
    if (route.params.cardCollection) {
      if (count >= route.params.cardCollection.cards.length) {
        AsyncStorage.removeItem(route.params.cardCollection.id)
      } else {
        AsyncStorage.setItem(route.params.cardCollection.id, String(count))
      }
    }
  }, [count])
  
  useEffect(() => {
    if (route.params.selectedCard) {
      setCount(Number(route.params.selectedCard))
    }
  }, [])
  
  useHeader(
    {
      backgroundColor,
      title: route.params.cardCollection
        ? route.params.cardCollection.title
        : specDeckTitle[route.params.specDeck.type],
      titleMode: "center",
      titleStyle: $headerTitleText,
      leftIconSvg: "back",
      leftIconSize: 20,
      onLeftPress: () => {
        navigation.navigate("BottomTab")
      },
    },
    [route.params.cardCollection],
  )
  
  return (
    <View style={[$container, {backgroundColor}]}>
      <SwipeCard
        demo={route.params.demo}
        cardCollectionId={route.params.cardCollection && route.params.cardCollection.id}
        setCount={setCount}
        count={count}
        cards={
          route.params.cardCollection
            ? getCardCollectionsMap.get(route.params.cardCollection.id).cards
            : route.params.specDeck.cards
        }
        selectedCard={route.params.selectedCard}
        endPress={endPress}
        backgroundColor={backgroundColor}
        questionPress={questionPress}
      />
      {route.params.cardCollection ? (
        <SwipeModal
          backgroundColor={backgroundColor}
          img={BASE_URL + route.params.cardCollection.img}
          bodyText={route.params.cardCollection.description}
          isModal={isSwipeModal}
          listData={data}
          setIsModal={setIsSwipeModal}
        />
      ) : <SwipeModal
        isModal={isSwipeModal}
        setIsModal={setIsSwipeModal}
      />}
    </View>
  )
})

const $container: ViewStyle = {
  flex: 1,
  paddingHorizontal: 16,
  alignItems: "center",
  justifyContent: "center",
}
