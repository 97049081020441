import i18n from "i18n-js"
import React, {RefObject} from "react"
import {StyleProp, Text as RNText, TextProps as RNTextProps, TextStyle} from "react-native"
import {isRTL, translate, TxKeyPath} from "../i18n"
import {colors, typography} from "../theme"
import {normalize} from "../utils/textHelp"

type Sizes = keyof typeof $sizeStyles
type Weights = keyof typeof typography.primary
type Presets = keyof typeof $presets

export interface TextProps extends RNTextProps {
  /**
   * Text which is looked up via i18n.
   */
  tx?: TxKeyPath
  /**
   * The text to display if not using `tx` or nested components.
   */
  text?: string
  /**
   * Optional options to pass to i18n. Useful for interpolation
   * as well as explicitly setting locale or translation fallbacks.
   */
  txOptions?: i18n.TranslateOptions
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<TextStyle>
  /**
   * One of the different types of text presets.
   */
  preset?: Presets
  /**
   * Text weight modifier.
   */
  weight?: Weights
  /**
   * Text size modifier.
   */
  size?: Sizes
  /**
   * Children components.
   */
  children?: React.ReactNode
  ref?: RefObject<any>
}

/**
 * For your text displaying needs.
 * This component is a HOC over the built-in React Native one.
 *
 * - [Documentation and Examples](https://github.com/infinitered/ignite/blob/master/docs/Components-Text.md)
 */
export function Text(props: TextProps) {
  const {weight, ref, size, tx, txOptions, text, children, style: $styleOverride, ...rest} = props
  
  const i18nText = tx && translate(tx, txOptions)
  const content = i18nText || text || children
  
  const preset: Presets = $presets[props.preset] ? props.preset : "default"
  const $styles = [
    $rtlStyle,
    $presets[preset],
    $fontWeightStyles[weight],
    $sizeStyles[size],
    $styleOverride,
  ]
  
  return (
    <RNText ref={ref} {...rest} style={$styles}>
      {content}
    </RNText>
  )
}

const $sizeStyles = {
  xxl: {fontSize: 34, lineHeight: 42, fontFamily: typography.primary.normal} as TextStyle,
  xl: {fontSize: 24, lineHeight: 34, fontFamily: typography.primary.normal} as TextStyle,
  lg: {fontSize: 20, lineHeight: 32, fontFamily: typography.primary.normal} as TextStyle,
  md: {fontSize: 18, lineHeight: 26, fontFamily: typography.primary.normal} as TextStyle,
  sm: {fontSize: 16, lineHeight: 24, fontFamily: typography.primary.normal} as TextStyle,
  xs: {fontSize: 14, lineHeight: 21, fontFamily: typography.primary.normal} as TextStyle,
  xxs: {fontSize: 12, lineHeight: 18, fontFamily: typography.primary.normal} as TextStyle,
  H1bold: {
    fontSize: 32,
    lineHeight: 38,
    fontWeight: "600",
    fontFamily: typography.secondary.normal,
  } as TextStyle,
  H2bold: {
    fontSize: 22,
    lineHeight: 28,
    fontWeight: "600",
    fontFamily: typography.secondary.normal,
  } as TextStyle,
  H2regular: {
    fontSize: 22,
    lineHeight: 28,
    fontWeight: "400",
    fontFamily: typography.secondary.normal,
  } as TextStyle,
  B1bold: {
    fontSize: 17,
    lineHeight: 24,
    fontWeight: "600",
    fontFamily: typography.primary.normal,
  } as TextStyle,
  B1regular: {
    fontSize: 17,
    lineHeight: 24,
    fontWeight: "400",
    fontFamily: typography.primary.normal,
  } as TextStyle,
  B2bold: {
    fontSize: 15,
    lineHeight: 22,
    fontWeight: "600",
    fontFamily: typography.primary.normal,
  } as TextStyle,
  B2regular: {
    fontSize: 15,
    lineHeight: 22,
    fontWeight: "400",
    fontFamily: typography.primary.normal,
  } as TextStyle,
  B3bold: {
    fontSize: 13,
    lineHeight: 18,
    fontWeight: "600",
    fontFamily: typography.primary.normal,
  } as TextStyle,
  B3regular: {
    fontSize: 13,
    lineHeight: 18,
    fontWeight: "400",
    fontFamily: typography.primary.normal,
  } as TextStyle,
}

const $fontWeightStyles = Object.entries(typography.primary).reduce((acc, [weight, fontFamily]) => {
  return {...acc, [weight]: {fontFamily}}
}, {}) as Record<Weights, TextStyle>

const $baseStyle: StyleProp<TextStyle> = [
  $sizeStyles.sm,
  $fontWeightStyles.normal,
  {color: colors.text},
]

const $presets = {
  default: $baseStyle,
  
  bold: [$baseStyle, $fontWeightStyles.bold] as StyleProp<TextStyle>,
  
  heading: [$baseStyle, $sizeStyles.xxl, $fontWeightStyles.bold] as StyleProp<TextStyle>,
  
  subheading: [$baseStyle, $sizeStyles.lg, $fontWeightStyles.medium] as StyleProp<TextStyle>,
  
  formLabel: [$baseStyle, $fontWeightStyles.medium] as StyleProp<TextStyle>,
  
  formHelper: [$baseStyle, $sizeStyles.sm, $fontWeightStyles.normal] as StyleProp<TextStyle>,
}

const $rtlStyle: TextStyle = isRTL ? {writingDirection: "rtl"} : {}
