// TODO: write documentation for colors and palette in own markdown file and add links from here

const palette = {
  neutral100: "#FFFFFF",
  neutral200: "#F4F2F1",
  neutral300: "#D7CEC9",
  neutral400: "#B6ACA6",
  neutral500: "#978F8A",
  neutral600: "#564E4A",
  neutral700: "#3C3836",
  neutral800: "#191015",
  neutral900: "#000000",

  primary100: "#F4E0D9",
  primary200: "#E8C1B4",
  primary300: "#DDA28E",
  primary400: "#D28468",
  primary500: "#C76542",
  primary600: "#A54F31",

  secondary100: "#DCDDE9",
  secondary200: "#BCC0D6",
  secondary300: "#9196B9",
  secondary400: "#626894",
  secondary500: "#41476E",

  accent100: "#FFEED4",
  accent200: "#FFE1B2",
  accent300: "#FDD495",
  accent400: "#FBC878",
  accent500: "#FFBB50",

  angry100: "#F2D6CD",
  angry500: "#C03403",

  overlay20: "rgba(25, 16, 21, 0.2)",
  overlay50: "rgba(25, 16, 21, 0.5)",
} as const

export const colorsFamiry = {
  accent: "#5653EC",
  black: "#2B2D33",
  lightGrey: "#F5F5F5",
  white: "#FFFFFF",
  grey140: "#404247",
  grey120: "#55575C",
  grey100: "#808185",
  grey60: "#AAABAD",
  grey30: "#D5D5D6",
  grey10: "#E9EAEA",
  grey05: "#F8F8F8",
  grey01: "#FBFBFB",
  success: "#539961",
  error: "#D05F54",
  link: "#4285F4",
  purple: "#F0F0FE",
  purple2: "#ECF2FF",
  green: "#F2F8E7",
  green2: "#ECF8EB",
  blue: "#E6F4FC",
  blue2: "#E4F7F8",
  pink: "#FDEFF5",
  pink2: "#FAEFFA",
  yellow: "#FEF6E1",
  yellow2: "#FDFFEB",
  orange: "#FFF1EB",
  orange2: "#FFF4E8",
  cardLockBG: "rgba(43,45,51,0.38)",
}

export const colors = {
  /**
   * The palette is available to use, but prefer using the name.
   * This is only included for rare, one-off cases. Try to use
   * semantic names as much as possible.
   */
  palette,
  cardBg: "#DB4437",
  cardBgBlock: "#EBA4A4",
  /**
   * A helper for making something see-thru.
   */
  transparent: "rgba(0, 0, 0, 0)",
  /**
   * The default text color in many components.
   */
  text: palette.neutral800,
  /**
   * Secondary text information.
   */
  textDim: palette.neutral600,
  /**
   * The default color of the screen background.
   */
  background: "#fff",
  /**
   * The default border color.
   */
  border: palette.neutral400,
  /**
   * The main tinting color.
   */
  tint: palette.primary500,
  /**
   * A subtle color used for lines.
   */
  separator: palette.neutral300,
  /**
   * Error messages.
   */
  error: palette.angry500,
  /**
   * Error Background.
   *
   */
  errorBackground: palette.angry100,
}
