import {Platform, Pressable, TextInput, TextStyle, View, ViewStyle} from "react-native"
import {colorsFamiry, typography} from "../../theme"
import {Text} from "../Text"
import React, {Dispatch, FC, useRef, useState} from "react"
import Shape from "../../../assets/icons/shape.svg"
import {observer} from "mobx-react-lite"
import Email from "../../../assets/icons/email.svg"
import {IS_WEB} from "../../utils/const"

export type MaskedInputProps = {
  setValue?: Dispatch<string>
  value?: string
  first?: boolean
}

export const EmailInput: FC<MaskedInputProps> = observer(function EmailInput({
                                                                               setValue,
                                                                               value,
                                                                               first,
                                                                             }) {
  const inputRef = useRef<TextInput>()
  const [focusInput, setFocusInput] = useState(false)
  
  const renderObj = {
    firstInput: (
      <View style={$shape}>
        <View style={$textContainer}>
          <Email />
          <Text style={$text} text={"email"} />
        </View>
      </View>
    ),
    emailInput: (
      <View style={$shape}>
        <View style={$inputContainer}>
          <TextInput
            ref={inputRef}
            autoFocus={true}
            onFocus={() => {
              setFocusInput(true)
            }}
            onBlur={() => {
              setFocusInput(false)
            }}
            placeholder={"email"}
            placeholderTextColor={colorsFamiry.grey30}
            style={$input}
            value={value}
            onChangeText={setValue}
          />
        </View>
        {value !== "" && (
          <Pressable
            onPress={() => {
              setValue("")
            }}
          >
            <Shape style={$icon} />
          </Pressable>
        )}
      </View>
    ),
  }
  
  return (
    <View style={[$container, focusInput && $inputFocused]}>
      {first ? renderObj.firstInput : renderObj.emailInput}
    </View>
  )
})

const topFirst = !IS_WEB && (Platform.OS === "ios" ? "-33%" : "-28%")
const topEmailInput = !IS_WEB && (Platform.OS === "ios" ? "-33%" : "-29%")

const $container: ViewStyle = {
  backgroundColor: colorsFamiry.lightGrey,
  borderColor: colorsFamiry.lightGrey,
  borderWidth: 1.5,
  borderRadius: 12,
  paddingLeft: 12,
  justifyContent: "center",
  flexDirection: IS_WEB ? undefined : "row",
  alignItems: IS_WEB ? undefined : "center",
  height: 50,
  
}
const $icon: ViewStyle = {
  marginLeft: 10,
  
}

const $input: TextStyle = {
  fontFamily: typography.primary.normal,
  fontSize: 16,
  lineHeight: 24,
  color: colorsFamiry.black,
  top: topEmailInput,
  position: "absolute",
  paddingVertical: 0,
  paddingLeft: 5,
  width: "100%",
  // @ts-ignore
  outlineWidth: IS_WEB ? 0 : undefined,
  outline: IS_WEB ? "none" : undefined,
}
const $inputFocused: TextStyle = {
  borderWidth: 1.5,
  borderColor: colorsFamiry.accent,
}
const $textContainer: ViewStyle = {
  top: topFirst,
  position: "absolute",
  paddingVertical: 0,
  paddingLeft: 5,
  flexDirection: "row",
  alignItems: "center",
}
const $text: TextStyle = {
  fontFamily: typography.primary.normal,
  fontSize: 16,
  lineHeight: 24,
  color: colorsFamiry.grey60,
  marginLeft: 10,
}
const $inputContainer: ViewStyle = {
  flexDirection: "row",
  paddingLeft: 5,
  flex: 1,
  alignItems: "center",
}
const $shape: ViewStyle = {
  width: "100%",
  paddingRight: 12,
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}
