import {Pressable, TextStyle, ViewStyle} from "react-native"
import React, {Dispatch} from "react"
import {Text} from "../Text"
import Check from "../../../assets/icons/setting/check.svg"
import {colorsFamiry} from "../../theme"

type Props = {
  item: {
    text: string
    id: string
    disable?: boolean
  }
  stateProp: {
    setState: Dispatch<string>
    state: string
  }
  last?: boolean
}

export function LanguageItem({item, stateProp, last}: Props) {
  return (
    <Pressable
      onPress={() => {
        if (!item.disable) stateProp.setState(item.id)
      }}
      style={[$container, last && $last]}
    >
      <Text size={"B2regular"} style={item.disable ? $disableText : $titleText} text={item.text} />
      {item.id === stateProp.state && <Check />}
    </Pressable>
  )
}

const $container: ViewStyle = {
  padding: 16,
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "row",
  borderBottomColor: colorsFamiry.lightGrey,
  borderBottomWidth: 1,
}
const $last: ViewStyle = {
  borderBottomWidth: 0,
}
const $titleText: TextStyle = {
  color: colorsFamiry.black,
}
const $disableText: TextStyle = {
  color: colorsFamiry.grey100,
}
