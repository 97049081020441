import { Translations } from "./ru"

const en: Translations = {
  common: {
    ok: "OK!",
    cancel: "Cancel",
    back: "Back",
    logOut: "LogOut",
    continue: "Continue",
    error: "Error",
    confirm: "Confirmation",
  },
  modalContinue: {
    continue: "Continue",
    again: "Again",
    title: "Do you want to continue?",
    body: "Have you played this game recently\nand have not completed",
  },
  bottomTab: {
    games: "Games",
    store: "Store",
    setting: "Setting",
  },
  policyScreen: {
    policy: "About the company",
    agreement: "User agreement",
    politics: "Privacy policy",
    position: "Regulation on the processing of personal data",
  },
  companyScreen: {
    about: "About the company",
    textOne:
      "We study family history, compile a family tree, and provide an opportunity to conduct independent genealogical research on archives in order to reconstruct the history of the family or the fate of a particular person.",
    textTwo:
      "The service was developed by a team of experts from the International Genealogical Center, each of whom has more than 20 years of experience in genealogy, family history and archival work.",
  },
  languageScreen: {
    language: "Language",
    ru: "Russian (RU)",
    en: "English (EN)",
  },
  swipeModal: {
    howToPlay: "How to play",
    body: "Here is a conversation game\nDescription\nDescription",
    one: "Read out the questions one by one to each other.",
    two: "Rules of the game in two beautiful \nlines",
    three: "Rules of the game in two beautiful \nlines",
    four: "Rules of the game in two beautiful \nlines",
    five: "Rules of the game in two beautiful \nlines",
    six: "Rules of the game in two beautiful \nlines",
  },
  welcomeScreen: {
    oneScreen: {
      title: "Here is a conversation game",
      text: "From the creators of the family history study service Famiry",
    },
    twoScreen: {
      title: "A game for those who want to be closer",
      text: "Sometimes just one question can open a new chapter in a relationship",
    },
    threeScreen: {
      title: "Different themes and decks to suit the mood",
      text: "There can be no right or wrong answers in the game",
    },
  },
  errorScreen: {
    title: "",
    friendlySubtitle: "",
    reset: "",
  },
  gameScreen: {
    from: " из ",
  },
  settingScreen: {
    setting: "Setting",
    language: {
      title: "Language",
      body: "English is now enabled",
    },
    shop: {
      title: "My purchases",
      body: "You haven't bought anything yet",
    },
    message: {
      title: "Feedback",
      body: "Write to us",
    },
    info: {
      title: "About us",
      body: "More about the project Famiry",
    },
    phone: "About the app",
  },
  storeScreen: {
    store: "Store",
    void: "There's nothing here yet",
    text: "But they will definitely appear.\nWe are working on it.",
  },
  emptyStateComponent: {
    generic: {
      heading: "",
      content: "",
      button: "",
    },
  },
  loginScreen: {
    codeAgain: "Send the code again",
    logInUsing: "Log in using",
    policy: "By continuing you agree to our Privacy Policy",
    firstText: "Some text why does the user necessarily need to log in",
    smsCodeSending: "The code has been sent to the number",
    send: "We will send it to the phone number\nSMS message with confirmation code",
    sendCode: "Get the code",
    signIn: "Login",
    enterDetails: "Enter your username and password Famiry Card",
    emailFieldLabel: "Email",
    passwordFieldLabel: "Password",
    emailFieldPlaceholder: "Enter email",
    passwordFieldPlaceholder: "Enter password",
    tapToSignIn: "Login",
    hint: "",
  },
  buttons: {
    continue: "Continue",
  },
}

export default en
