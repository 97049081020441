/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { QuizQuestionModel, QuizQuestionModelType } from "./QuizQuestionModel"
import { QuizQuestionModelSelector } from "./QuizQuestionModel.base"
import { QuizResultOptionModel, QuizResultOptionModelType } from "./QuizResultOptionModel"
import { QuizResultOptionModelSelector } from "./QuizResultOptionModel.base"
import { RootStoreType } from "./index"


/**
 * QuizBase
 * auto generated base class for the model QuizModel.
 */
export const QuizModelBase = ModelBase
  .named('Quiz')
  .props({
    __typename: types.optional(types.literal("Quiz"), "Quiz"),
    id: types.identifier,
    createdAt: types.union(types.undefined, types.frozen()),
    updatedAt: types.union(types.undefined, types.frozen()),
    title: types.union(types.undefined, types.string),
    description: types.union(types.undefined, types.string),
    questions: types.union(types.undefined, types.null, types.array(types.late((): any => QuizQuestionModel))),
    resultOptions: types.union(types.undefined, types.array(types.late((): any => QuizResultOptionModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class QuizModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  get title() { return this.__attr(`title`) }
  get description() { return this.__attr(`description`) }
  questions(builder: string | QuizQuestionModelSelector | ((selector: QuizQuestionModelSelector) => QuizQuestionModelSelector) | undefined) { return this.__child(`questions`, QuizQuestionModelSelector, builder) }
  resultOptions(builder: string | QuizResultOptionModelSelector | ((selector: QuizResultOptionModelSelector) => QuizResultOptionModelSelector) | undefined) { return this.__child(`resultOptions`, QuizResultOptionModelSelector, builder) }
}
export function selectFromQuiz() {
  return new QuizModelSelector()
}

export const quizModelPrimitives = selectFromQuiz().createdAt.updatedAt.title.description
