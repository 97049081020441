/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * CardBase
 * auto generated base class for the model CardModel.
 */
export const CardModelBase = ModelBase
  .named('Card')
  .props({
    __typename: types.optional(types.literal("Card"), "Card"),
    id: types.identifier,
    createdAt: types.union(types.undefined, types.frozen()),
    updatedAt: types.union(types.undefined, types.frozen()),
    title: types.union(types.undefined, types.string),
    question: types.union(types.undefined, types.string),
    index: types.union(types.undefined, types.number),
    likesCount: types.union(types.undefined, types.number),
    dislikesCount: types.union(types.undefined, types.number),
    isLikedByUser: types.union(types.undefined, types.null, types.boolean),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class CardModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  get title() { return this.__attr(`title`) }
  get question() { return this.__attr(`question`) }
  get index() { return this.__attr(`index`) }
  get likesCount() { return this.__attr(`likesCount`) }
  get dislikesCount() { return this.__attr(`dislikesCount`) }
  get isLikedByUser() { return this.__attr(`isLikedByUser`) }
}
export function selectFromCard() {
  return new CardModelSelector()
}

export const cardModelPrimitives = selectFromCard().createdAt.updatedAt.title.question.index.likesCount.dislikesCount.isLikedByUser
