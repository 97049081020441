import {BottomTabScreenProps, createBottomTabNavigator} from "@react-navigation/bottom-tabs"
import {CompositeScreenProps} from "@react-navigation/native"
import React from "react"
import {TextStyle, View, ViewStyle} from "react-native"
import {useSafeAreaInsets} from "react-native-safe-area-context"
import {Text} from "../components"
import {colors, colorsFamiry, spacing, typography} from "../theme"
import {AppStackParamList, AppStackScreenProps} from "./AppNavigator"
import {StoreScreen} from "../screens/Bottom/StoreScreen"
import {GameBottom} from "../components/icons/GameBottom"
import {StoreBottom} from "../components/icons/StoreBottom"
import {SettingBottom} from "../components/icons/SettingBottom"
import {SettingStack} from "./SettingStack"
import {observer} from "mobx-react-lite"
import {GamesScreen} from "../screens/Bottom/GamesScreen"
import {translate} from "../i18n"
import {useStores} from "../models"

export type BottomTabParamList = {
  GamesScreen: undefined
  StoreScreen: undefined
  SettingStack: undefined
}

export type BottomScreenProps<T extends keyof BottomTabParamList> = CompositeScreenProps<BottomTabScreenProps<BottomTabParamList, T>,
  AppStackScreenProps<keyof AppStackParamList>>

const Tab = createBottomTabNavigator<BottomTabParamList>()

export const BottomNavigator = observer(function BottomNavigator() {
  const {bottom} = useSafeAreaInsets()
  const {loading} = useStores().authenticationStore
  return (
    <Tab.Navigator
      
      screenOptions={{
        headerShown: false,
        tabBarHideOnKeyboard: true,
        tabBarStyle: [$tabBar, {height: bottom + 70}, {display: loading ? "none" : "flex"}],
        tabBarActiveTintColor: colors.text,
        tabBarInactiveTintColor: colors.text,
        tabBarItemStyle: $tabBarItem,
        tabBarLabel: "",
      }}
      initialRouteName={"GamesScreen"}
      sceneContainerStyle={$background}
    >
      <Tab.Screen
        name="GamesScreen"
        component={GamesScreen}
        options={{
          tabBarIcon: ({focused}) => (
            <View style={$iconContainer}>
              <GameBottom active={focused} size={25} />
              <Text size={"xxs"} style={focused ? $textActive : $text}>
                {translate("bottomTab.games")}
              </Text>
            </View>
          ),
        }}
      />
      
      <Tab.Screen
        name="StoreScreen"
        component={StoreScreen}
        options={{
          tabBarIcon: ({focused}) => (
            <View style={$iconContainer}>
              <StoreBottom active={focused} size={25} />
              <Text size={"xxs"} style={focused ? $textActive : $text}>
                {translate("bottomTab.store")}
              </Text>
            </View>
          ),
        }}
      />
      
      <Tab.Screen
        name="SettingStack"
        component={SettingStack}
        options={{
          tabBarIcon: ({focused}) => (
            <View style={$iconContainer}>
              <SettingBottom active={focused} size={25} />
              <Text size={"xxs"} style={focused ? $textActive : $text}>
                {translate("bottomTab.setting")}
              </Text>
            </View>
          ),
        }}
      />
    </Tab.Navigator>
  )
})

const $tabBar: ViewStyle = {
  backgroundColor: colors.background,
  borderTopColor: colors.transparent,
}

const $background: ViewStyle = {
  backgroundColor: colorsFamiry.white,
}

const $iconContainer: ViewStyle = {
  borderRadius: 12,
  width: 70,
  height: 50,
  alignItems: "center",
  justifyContent: "center",
}

const $tabBarItem: ViewStyle = {
  paddingTop: spacing.medium,
}
const $text: TextStyle = {
  color: colorsFamiry.grey100,
}
const $textActive: TextStyle = {
  color: colorsFamiry.accent,
}
