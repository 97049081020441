import {Alert, Pressable, ViewStyle} from "react-native"
import {Text} from "../Text"
import Heard from "../../../assets/icons/cards/heard.svg"
import Star from "../../../assets/icons/cards/star.svg"
import React, {Dispatch, FC} from "react"
import {colorsFamiry} from "../../theme"
import {observer} from "mobx-react-lite"
import {useStores} from "../../models"
import {StackNavigationProp} from "@react-navigation/stack"
import {AppStackParamList} from "../../navigators"

export type SpecDeckProps = {
  style?: ViewStyle
  type: "popular" | "favorites"
  navigation: StackNavigationProp<AppStackParamList>
  setIsModal?: Dispatch<boolean>
}

export const SpecDeck: FC<SpecDeckProps> = observer(function SpecDeck({setIsModal, style, type, navigation}) {
  const {queryFavoriteCards, queryPopularCards} = useStores()
  
  const content = {
    popular: {
      text: "Популярные\nвопросы",
      icon: Star,
      onClick: async () => {
        await queryPopularCards({}).then((r) => {
          console.log(r.popularCards)
          navigation.navigate("GameScreen", {
            specDeck: {
              type: "popular",
              cards: r.popularCards,
            },
          })
        })
      },
    },
    favorites: {
      text: "Избранные\nвопросы",
      icon: Heard,
      onClick: async () => {
        await queryFavoriteCards({}).then((r) => {
          console.log(r.favoriteCards)
          if (r.favoriteCards.length === 0) {
            if (setIsModal) setIsModal(true)
            // Alert.alert("", "У вас нет избранных вопросов")
          } else {
            navigation.navigate("GameScreen", {
              specDeck: {
                type: "favorites",
                cards: r.favoriteCards,
              },
            })
          }
        })
      },
    },
  }
  
  const Icon = content[type].icon
  
  const onClick = async () => {
    await content[type].onClick()
  }
  
  return (
    <Pressable onPress={onClick} style={[$cardContainer, style]}>
      <Text>{content[type].text}</Text>
      <Icon />
    </Pressable>
  )
})

const $cardContainer: ViewStyle = {
  borderRadius: 16,
  padding: 20,
  marginBottom: 16,
  flex: 1,
  backgroundColor: colorsFamiry.lightGrey,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
}
