/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * CardCollectionTagBase
 * auto generated base class for the model CardCollectionTagModel.
 */
export const CardCollectionTagModelBase = ModelBase
  .named('CardCollectionTag')
  .props({
    __typename: types.optional(types.literal("CardCollectionTag"), "CardCollectionTag"),
    id: types.identifier,
    createdAt: types.union(types.undefined, types.frozen()),
    updatedAt: types.union(types.undefined, types.frozen()),
    title: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class CardCollectionTagModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  get title() { return this.__attr(`title`) }
}
export function selectFromCardCollectionTag() {
  return new CardCollectionTagModelSelector()
}

export const cardCollectionTagModelPrimitives = selectFromCardCollectionTag().createdAt.updatedAt.title
