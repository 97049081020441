/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * QuizQuestionOptionBase
 * auto generated base class for the model QuizQuestionOptionModel.
 */
export const QuizQuestionOptionModelBase = ModelBase
  .named('QuizQuestionOption')
  .props({
    __typename: types.optional(types.literal("QuizQuestionOption"), "QuizQuestionOption"),
    id: types.identifier,
    createdAt: types.union(types.undefined, types.frozen()),
    updatedAt: types.union(types.undefined, types.frozen()),
    option: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class QuizQuestionOptionModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  get option() { return this.__attr(`option`) }
}
export function selectFromQuizQuestionOption() {
  return new QuizQuestionOptionModelSelector()
}

export const quizQuestionOptionModelPrimitives = selectFromQuizQuestionOption().createdAt.updatedAt.option
