import {Instance} from "mobx-state-tree"
import {RootStoreBase} from "./RootStore.base"

export interface RootStoreType extends Instance<typeof RootStore.Type> {}

export const RootStore = RootStoreBase.actions((self) => ({
  setLike(idCollection, cardId, isLiked, likesCount) {
    self.cardCollections = self.cardCollections.set(idCollection, {
      ...self.cardCollections.get(idCollection),
      cards: self.cardCollections.get(idCollection).cards.map((card) => {
        if (card.id === cardId) {
          return {...card, isLikedByUser: isLiked, likesCount}
        } else return card
      }),
    })
  },
  log() {
    console.log(JSON.stringify(self))
  },
}))
  .props({
    // cardCollectionsArray: types.optional(types.array(CardCollectionModel), []),
  })
  .views((self) => ({
    get getCardCollections() {
      return Array.from(self.cardCollections)
    },
    get getCardCollectionsMap() {
      return self.cardCollections
    },
    get me() {
      return Array.from(self.users)[0]
    },
  }))
