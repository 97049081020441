// @flow
import React from "react"
import {View, ViewStyle} from "react-native"
import {colorsFamiry} from "../../theme"
import {Text} from "../Text"

type Props = {
  item: string
}

export function HelpItem({item}: Props) {
  return (
    <View style={$container}>
      {<View style={$numberContainer} />}
      {<Text size={"B3regular"} text={item} />}
    </View>
  )
}

const $container: ViewStyle = {
  flexDirection: "row",
  marginBottom: 8,
  paddingRight: 20,
}

const $numberContainer: ViewStyle = {
  height: 8,
  width: 8,
  marginTop: 6,
  marginRight: 12,
  backgroundColor: colorsFamiry.accent,
  borderRadius: 12,
}
