import {createNativeStackNavigator} from "@react-navigation/native-stack"
import {StackScreenProps} from "@react-navigation/stack"
import {observer} from "mobx-react-lite"
import React from "react"
import {SettingScreen} from "../screens/Bottom/SettingScreen"
import {LanguageScreen} from "../screens/Bottom/setting/LanguageScreen"
import {CompanyScreen} from "../screens/Bottom/setting/CompanyScreen"
import {PolicyScreen} from "../screens/Bottom/setting/PolicyScreen"
import {colorsFamiry} from "../theme"

export type SettingStackParamList = {
  SettingScreen: undefined
  LanguageScreen: undefined
  CompanyScreen: undefined
  PolicyScreen: undefined
}

export type SettingStackScreenProps<T extends keyof SettingStackParamList> = StackScreenProps<SettingStackParamList,
  T>

const Stack = createNativeStackNavigator<SettingStackParamList>()

export const SettingStack = observer(function SettingStack() {
  return (
    <Stack.Navigator
      screenOptions={{headerShown: false, contentStyle: {flex: 1, backgroundColor: colorsFamiry.white}}}
      initialRouteName={"SettingScreen"}
    >
      <Stack.Screen name="SettingScreen" component={SettingScreen} />
      <Stack.Screen
        name="LanguageScreen"
        options={{animation: "slide_from_right"}}
        component={LanguageScreen}
      />
      <Stack.Screen
        name="CompanyScreen"
        options={{animation: "slide_from_right"}}
        component={CompanyScreen}
      />
      <Stack.Screen
        name="PolicyScreen"
        options={{animation: "slide_from_right"}}
        component={PolicyScreen}
      />
    </Stack.Navigator>
  )
})
