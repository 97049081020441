import * as Notifications from "expo-notifications"
// export const getFcmToken = async () => {
//   try {
//     const newFcmToken = await messaging().getToken()
//     console.log(newFcmToken)
//     return newFcmToken
//   } catch (error) {
//     console.error(error)
//     return null
//   }
// }
export const requestUserPermission = async () => {
  const permissions = await Notifications.getPermissionsAsync()
  if (permissions.status === "granted") {
  } else {
    const permissionsFinal = await Notifications.requestPermissionsAsync()
    if (permissionsFinal.status === "granted") {
    }
  }
}

// export const notificationListener = () => {
//   messaging().onNotificationOpenedApp((remoteMessage) => {
//     console.log(
//       "Notification caused app to open from background state:",
//       remoteMessage.notification
//     )
//   })
//
//   // Quiet and Background State -> Check whether an initial notification is available
//   messaging()
//     .getInitialNotification()
//     .then((remoteMessage) => {
//       if (remoteMessage) {
//         console.log("Notification caused app to open from quit state:", remoteMessage.notification)
//       }
//     })
//     .catch((error) => console.log("failed", error))
//
//   // Foreground State
//   messaging().onMessage(async (remoteMessage) => {
//     console.log("foreground", remoteMessage)
//   })
// }
