import React, {Dispatch, FC, useRef, useState} from "react"
import {Dimensions, Image, ImageStyle, Pressable, ScrollView, TextStyle, View, ViewStyle} from "react-native"
import CloseLine from "../../../assets/icons/closeLine.svg"
import {Text} from "../Text"
import Modal from "react-native-modal"
import {colorsFamiry} from "../../theme"
import {HelpItem} from "../help/HelpItem"
import {translate} from "../../i18n"
import {observer} from "mobx-react-lite"
import {MAX_WIDTH} from "../../utils/const"
import CloseIcon from "../../../assets/icons/closeIcon.svg"

export type SwipeModalProps = {
  isModal: boolean
  setIsModal: Dispatch<boolean>
  bodyText?: string
  secondBodyText?: string
  listData?: string[]
  backgroundColor?: string
  img?: string
}

const data = translate("swipeModal.text")

const {height} = Dimensions.get("window")

export const SwipeModal: FC<SwipeModalProps> = observer(function SwipeModal({
                                                                              isModal,
                                                                              setIsModal,
                                                                              bodyText,
                                                                              secondBodyText,
                                                                              listData,
                                                                              backgroundColor,
                                                                              img,
                                                                            }) {
  const [scrollOffset, setScrollOffset] = useState(null)
  const list = listData || data.split(";")
  const scrollViewRef = useRef<ScrollView>()
  const imgSource = img ? {uri: img} : require("../../../assets/images/helpImage.png")
  const color = backgroundColor || colorsFamiry.lightGrey
  
  const handleOnScroll = (event) => {
    setScrollOffset(event.nativeEvent.contentOffset.y)
  }
  const handleScrollTo = (p) => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo(p)
    }
  }
  const onClosePress = () => {
    setIsModal(false)
  }
  return (
    <Modal
      
      testID={"modal"}
      isVisible={isModal}
      onSwipeComplete={() => {
        setIsModal(false)
      }}
      onBackdropPress={() => {
        setIsModal(false)
      }}
      swipeDirection={["down"]}
      scrollTo={handleScrollTo}
      scrollOffset={scrollOffset}
      scrollOffsetMax={400 - 300}
      propagateSwipe={true}
      style={$modal}
    >
      <View style={$scrollableModal}>
        <View style={$closeLineContainer}>
          <CloseLine />
        </View>
        <Pressable style={$icon} onPress={onClosePress}>
          <CloseIcon />
        </Pressable>
        <Text style={$titleText} size={"H2bold"} text={translate("swipeModal.howToPlay")} />
        <ScrollView
          showsVerticalScrollIndicator={false}
          ref={scrollViewRef}
          onScroll={handleOnScroll}
          scrollEventThrottle={16}
        >
          <View style={[$imageContainer, {backgroundColor: color}]}>
            <Image style={$image} source={imgSource} resizeMode={"contain"} />
          </View>
          <View style={$textContainer}>
            <Text style={$descText} size={"B2regular"} text={bodyText} />
            {secondBodyText && <Text style={$descText} size={"B2regular"} text={secondBodyText} />}
          </View>
          {list.map((item, index) => (
            <HelpItem key={index} item={item.trim()} />
          ))}
        </ScrollView>
      </View>
    </Modal>
  )
})
const $modal: ViewStyle = {
  justifyContent: "flex-end",
  margin: 0,
  alignItems: "center",
}
const $image: ImageStyle = {
  width: "75%",
  height: "100%",
}

const $icon: ViewStyle = {
  position: "absolute",
  top: 20,
  right: 20,
}
const $imageContainer: ViewStyle = {
  borderRadius: 24,
  height: (height / 100) * 30,
  width: "100%",
  alignItems: "center",
  paddingTop: 20,
}

const $scrollableModal: ViewStyle = {
  height: height - 70,
  borderTopLeftRadius: 24,
  borderTopRightRadius: 24,
  backgroundColor: colorsFamiry.white,
  paddingHorizontal: 16,
  maxWidth: MAX_WIDTH,
}

const $closeLineContainer: ViewStyle = {
  width: "100%",
  alignItems: "center",
  marginTop: 12,
  marginBottom: 16,
}
const $textContainer: ViewStyle = {
  paddingTop: 16,
  paddingBottom: 8,
}
const $descText: TextStyle = {
  marginBottom: 8,
}
const $titleText: TextStyle = {
  marginBottom: 20,
}
