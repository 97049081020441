/* eslint-disable  react-native/no-inline-styles */
import React, {FC, useState} from "react"
import {View, ViewStyle} from "react-native"
import {useHeader} from "../../../utils/useHeader"
import {SettingStackScreenProps} from "../../../navigators/SettingStack"
import {LanguageItem} from "../../../components/setting/LanguageItem"
import {$headerTitleText} from "../../../theme/style"
import {translate} from "../../../i18n"
import I18n from "i18n-js"
import {observer} from "mobx-react-lite"

interface LanguageScreenProps extends SettingStackScreenProps<"LanguageScreen"> {}

const data = [
  {
    text: translate("languageScreen.ru"),
    id: "ru-RU",
  },
  {
    text: translate("languageScreen.en"),
    id: "en-US",
    disable: true,
  },
  {
    text: translate("languageScreen.es"),
    id: "es-ES",
    disable: true,
  },
]

export const LanguageScreen: FC<LanguageScreenProps> = observer(function LanguageScreen({
  navigation,
}) {
  const [selectLang, setSelectLang] = useState(I18n.locale)

  useHeader({
    title: translate("languageScreen.language"),
    titleMode: "center",
    leftIconSvg: "back",
    leftIconSize: 20,
    titleStyle: $headerTitleText,
    onLeftPress: () => {
      navigation.goBack()
    },
  })

  return (
    <View style={$container}>
      {data.map((item, index) => (
        <LanguageItem
          last={index + 1 === data.length}
          key={item.id}
          item={item}
          stateProp={{state: selectLang, setState: setSelectLang}}
        />
      ))}
    </View>
  )
})
const $container: ViewStyle = {
  flex: 1,
}
