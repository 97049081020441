import {observer} from "mobx-react-lite"
import {Image, View, ViewStyle, ImageStyle, ScrollView, Dimensions, Alert} from "react-native"
import {useHeader} from "../utils/useHeader"
import React, {FC, useState} from "react"
import {AppStackScreenProps} from "../navigators"
import {SwipeModal} from "../components/newComponents/SwipeModal"
import {Button, Text} from "../components"
import {HelpItem} from "../components/help/HelpItem"
import {$headerTitleText} from "../theme/style"
import {BASE_URL} from "../utils/const"

interface GamePreviewScreenProps extends AppStackScreenProps<"GamePreviewScreen"> {}

const {height} = Dimensions.get("window")

export const GamePreviewScreen: FC<GamePreviewScreenProps> = observer(function GamePreviewScreen({
  route,
  navigation,
}) {
  const [isModal, setIsModal] = useState(false)
  const data = route.params.cardCollection.howToPlay.split(";").filter((item) => item !== "")
  useHeader(
    {
      title: route.params.cardCollection.title,
      titleMode: "center",
      titleStyle: $headerTitleText,
      leftIconSvg: "back",
      leftIconSize: 20,
      onLeftPress: () => {
        navigation.goBack()
      },
    },
    [route.params.cardCollection]
  )

  const onPressGame = () => {
    if (route.params.cardCollection.cards.length > 0) {
      navigation.navigate("GameScreen", {
        cardCollection: route.params.cardCollection,
        demo: route.params.demo,
      })
    } else {
      Alert.alert("", "В данной коллекции пока нет карточек")
    }
  }

  return (
    <View style={$container}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={[
            $imageContainer,
            {backgroundColor: "#" + route.params.cardCollection.backgroundColor},
          ]}
        >
          <Image
            style={$image}
            source={{uri: BASE_URL + route.params.cardCollection.img}}
            resizeMode={"stretch"}
          />
        </View>
        <Text size={"B2regular"} text={route.params.cardCollection.description} />
        <View style={$listContainer}>
          {data.map((item, index) => (
            <HelpItem key={index} item={item.trim()} />
          ))}
        </View>
      </ScrollView>
      <Button onPress={onPressGame} style={$btn} preset={"blue"} text={"Играть"} />
      <SwipeModal
        bodyText={route.params.cardCollection.description}
        isModal={isModal}
        listData={data}
        img={BASE_URL + route.params.cardCollection.img}
        backgroundColor={"#" + route.params.cardCollection.backgroundColor}
        setIsModal={setIsModal}
      />
    </View>
  )
})

const $container: ViewStyle = {
  flex: 1,
  paddingHorizontal: 16,
}
const $listContainer: ViewStyle = {
  marginBottom: 80,
  marginTop: 20,
}

const $imageContainer: ViewStyle = {
  borderRadius: 24,
  height: (height / 100) * 30,
  width: "100%",
  alignItems: "center",
  paddingTop: 20,
  marginBottom: 16,
}

const $image: ImageStyle = {
  width: "75%",
  height: "100%",
}

const $btn: ViewStyle = {
  borderRadius: 16,
  marginBottom: 10,
  paddingVertical: 16,
  position: "absolute",
  bottom: 10,
  left: 16,
  right: 16,
}
