/* eslint-disable  react-native/no-inline-styles */
import React, {FC} from "react"
import {Linking, View, ViewStyle} from "react-native"
import {useHeader} from "../../../utils/useHeader"
import {SettingStackScreenProps} from "../../../navigators/SettingStack"
import {SettingItem} from "../../../components/setting/SettingItem"
import {$headerTitleText} from "../../../theme/style"
import {translate} from "../../../i18n"
import {observer} from "mobx-react-lite"

interface PolicyScreenProps extends SettingStackScreenProps<"PolicyScreen"> {}

const data = [
  {
    title: translate("policyScreen.agreement"),
    onPress: () => {
      Linking.openURL("https://famiry.ru/services/terms")
    },
  },
  {
    title: translate("policyScreen.position"),
    onPress: () => {
      Linking.openURL("https://famiry.ru/services/policy")
    },
  },
]

export const PolicyScreen: FC<PolicyScreenProps> = observer(function PolicyScreen({navigation}) {
  useHeader({
    title: translate("policyScreen.policy"),
    titleMode: "center",
    leftIconSvg: "back",
    leftIconSize: 20,
    titleStyle: $headerTitleText,
    onLeftPress: () => {
      navigation.goBack()
    },
  })

  return (
    <View style={$container}>
      {data.map((item, index) => (
        <SettingItem last={index + 1 === data.length} key={item.title} item={item} />
      ))}
    </View>
  )
})
const $container: ViewStyle = {
  flex: 1,
}
