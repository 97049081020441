/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { CardDislikeModel, CardDislikeModelType } from "./CardDislikeModel"
import { CardDislikeModelSelector } from "./CardDislikeModel.base"
import { CardLikeModel, CardLikeModelType } from "./CardLikeModel"
import { CardLikeModelSelector } from "./CardLikeModel.base"
import { CardModel, CardModelType } from "./CardModel"
import { CardModelSelector } from "./CardModel.base"
import { RootStoreType } from "./index"


/**
 * UserBase
 * auto generated base class for the model UserModel.
 */
export const UserModelBase = ModelBase
  .named('User')
  .props({
    __typename: types.optional(types.literal("User"), "User"),
    id: types.identifier,
    createdAt: types.union(types.undefined, types.frozen()),
    updatedAt: types.union(types.undefined, types.frozen()),
    fcm_token: types.union(types.undefined, types.string),
    famiryId: types.union(types.undefined, types.string),
    shortFamiryId: types.union(types.undefined, types.null, types.string),
    username: types.union(types.undefined, types.null, types.string),
    name: types.union(types.undefined, types.null, types.string),
    surname: types.union(types.undefined, types.null, types.string),
    patronymic: types.union(types.undefined, types.null, types.string),
    displayname: types.union(types.undefined, types.null, types.string),
    email: types.union(types.undefined, types.string),
    phoneRaw: types.union(types.undefined, types.null, types.string),
    gender: types.union(types.undefined, types.null, types.string),
    birthdate: types.union(types.undefined, types.null, types.frozen()),
    avatarUrl: types.union(types.undefined, types.null, types.string),
    initialRole: types.union(types.undefined, types.null, types.string),
    likes: types.union(types.undefined, types.null, types.array(types.late((): any => CardLikeModel))),
    dislikes: types.union(types.undefined, types.null, types.array(types.late((): any => CardDislikeModel))),
    favoriteCards: types.union(types.undefined, types.array(types.late((): any => CardModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class UserModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  get fcm_token() { return this.__attr(`fcm_token`) }
  get famiryId() { return this.__attr(`famiryId`) }
  get shortFamiryId() { return this.__attr(`shortFamiryId`) }
  get username() { return this.__attr(`username`) }
  get name() { return this.__attr(`name`) }
  get surname() { return this.__attr(`surname`) }
  get patronymic() { return this.__attr(`patronymic`) }
  get displayname() { return this.__attr(`displayname`) }
  get email() { return this.__attr(`email`) }
  get phoneRaw() { return this.__attr(`phoneRaw`) }
  get gender() { return this.__attr(`gender`) }
  get birthdate() { return this.__attr(`birthdate`) }
  get avatarUrl() { return this.__attr(`avatarUrl`) }
  get initialRole() { return this.__attr(`initialRole`) }
  likes(builder: string | CardLikeModelSelector | ((selector: CardLikeModelSelector) => CardLikeModelSelector) | undefined) { return this.__child(`likes`, CardLikeModelSelector, builder) }
  dislikes(builder: string | CardDislikeModelSelector | ((selector: CardDislikeModelSelector) => CardDislikeModelSelector) | undefined) { return this.__child(`dislikes`, CardDislikeModelSelector, builder) }
  favoriteCards(builder: string | CardModelSelector | ((selector: CardModelSelector) => CardModelSelector) | undefined) { return this.__child(`favoriteCards`, CardModelSelector, builder) }
}
export function selectFromUser() {
  return new UserModelSelector()
}

export const userModelPrimitives = selectFromUser().createdAt.updatedAt.fcm_token.famiryId.shortFamiryId.username.name.surname.patronymic.displayname.email.phoneRaw.gender.birthdate.avatarUrl.initialRole
