import React, {Dispatch, useEffect, useRef} from "react"
import {
  Dimensions,
  Image,
  ImageSourcePropType,
  ImageStyle,
  TextStyle,
  TouchableOpacityProps,
  View,
  ViewStyle,
} from "react-native"
import {Text} from "./Text"
import {normalize, SCREEN_HEIGHT} from "../utils/textHelp"

const {width} = Dimensions.get("window")
const {height} = Dimensions.get("window")

interface PreviewScreenProps extends TouchableOpacityProps {
  item: {
    title: string
    text: string
    image: ImageSourcePropType
    onPressCloseButton?: () => void
  }
  setPosition: Dispatch<number>
  position: number
}

export function PreviewScreen(props: PreviewScreenProps) {
  const {item, setPosition, position} = props

  const ref = useRef<View>(null)

  return (
    <View style={$container}>
      <View
        ref={ref}
        onLayout={(event) => {
          console.log("Y: " + event.nativeEvent.layout.y)
          console.log("height: " + event.nativeEvent.layout.height)
          setPosition(event.nativeEvent.layout.height)
        }}
        style={$textContainer}
      >
        <Text style={$titleText} size={"H1bold"} text={item.title} />
        <Text style={$text} size={"B1regular"} text={item.text} />
      </View>
      <View
        style={{
          width,
          height: (height / 100) * 100,
          position: "absolute",
          top: position && position + 100,
        }}
      >
        <Image source={item.image} style={$image} resizeMode={"cover"} />
      </View>
    </View>
  )
}

const $container: ViewStyle = {
  width,
}

const $textContainer: ViewStyle = {
  width,
  paddingHorizontal: 26,
  alignItems: "center",
}
const $titleText: TextStyle = {
  textAlign: "center",
  marginBottom: 16,
}
const $text: TextStyle = {
  textAlign: "center",
}

const $image: ImageStyle = {
  width,
  height: "65%",
}
