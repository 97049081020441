import {Pressable, TextStyle, View, ViewStyle} from "react-native"
import {SvgProps} from "react-native-svg"
import React from "react"
import {Text} from "../Text"
import Arrow from "../../../assets/icons/arrowRight.svg"
import {colorsFamiry} from "../../theme"

type Props = {
  item: {
    title: string
    body?: string
    icon?: React.FC<SvgProps>
    onPress: () => void
  }
  last?: boolean
}

export function SettingItem({item, last}: Props) {
  return (
    <Pressable onPress={item.onPress} style={[$container, last && $last]}>
      <View style={$containerText}>
        {item.icon && <item.icon style={$icon} />}
        <View>
          <Text size={"B2regular"} style={$titleText} text={item.title} />
          {item.body && <Text size={"B3regular"} style={$bodyText} text={item.body} />}
        </View>
      </View>
      <Arrow />
    </Pressable>
  )
}

const $icon: ViewStyle = {
  marginTop: 2,
  marginRight: 16,
}

const $containerText: ViewStyle = {
  flexDirection: "row",
}
const $last: ViewStyle = {
  borderBottomWidth: 0,
}

const $container: ViewStyle = {
  padding: 16,
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "row",
  borderBottomColor: colorsFamiry.lightGrey,
  borderBottomWidth: 1,
}

const $titleText: TextStyle = {
  color: colorsFamiry.black,
}
const $bodyText: TextStyle = {
  color: colorsFamiry.grey60,
}
